/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../fonts/poppins/pxiGyp8kv8JHgFVrLPTufntAKPY.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../fonts/poppins/pxiGyp8kv8JHgFVrLPTucHtA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLFj_Z1JlFc-K.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLFj_Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLDz8Z1JlFc-K.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/poppins/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/poppins/pxiEyp8kv8JHgFVrJJfecg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLDD4Z1JlFc-K.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLDD4Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLBT5Z1JlFc-K.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLBT5Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
body {
  background-color: #f3f3f3 !important;
}

* {
  font-family: "Poppins", sans-serif !important;
}

.lefrightcolspacing {
  padding: 0px 0.5rem;
}

.filterhere {
  position: relative;
}
.filterhere .nav.nav-tabs {
  width: -moz-fit-content;
  width: fit-content;
}
.filterhere button {
  font-size: 15px;
}
.filterhere .nav-link {
  color: #333333;
  position: relative;
  border-top: none;
  border-left: none;
  border-right: none;
  border-color: #c4c4c4;
  font-weight: 400;
  opacity: 50%;
  font-size: 13px;
}
.filterhere .nav-link:hover {
  border-color: #ca373c;
  opacity: 1;
}
.filterhere .nav-link.active {
  border-color: #ca373c;
  background: none;
  font-weight: 500;
  opacity: 1;
  color: #333333;
}
.filterhere .nav-link.active::after {
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
          clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background-color: #ca373c;
  width: 11px;
  height: 7px;
  position: absolute;
  content: "";
  bottom: -4px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.filterparent {
  position: absolute;
  top: 10px;
  right: 10px;
}

.filteroption span {
  display: flex;
  padding: 9px 10px;
  border-radius: 5px;
  background: #ca373c;
  gap: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: -moz-max-content;
  width: max-content;
  color: #fff;
  font-size: 14px;
}
.filteroption span svg {
  color: #fff;
  width: 20px;
  cursor: pointer;
  padding-right: 5px;
  margin-top: -2px;
}
.filteroption .midline {
  height: 100%;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.5411764706);
}

.flipbtn {
  position: absolute;
  right: 4%;
  top: 3%;
  color: #9999a1;
  z-index: 2;
}
.flipbtn svg {
  width: 1.25rem;
  height: 1.25rem;
}

.groupbody {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: 1;
}
.groupbody .grouplogo {
  background: rgba(223, 225, 235, 0.4);
  max-width: 70px;
  width: 100%;
  height: 70px;
  line-height: 70px;
  border-radius: 50%;
  position: relative;
  overflow: visible;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.groupbody .grouplogo img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  padding: 10px;
}
.groupbody .grouplogo p {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0;
}
.groupbody .grouplogo .activedot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #0bc50b;
  position: absolute;
  bottom: 10%;
  right: 10%;
}
.groupbody .groupdetails {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.groupbody .groupdetails .cn {
  font-weight: 500;
  color: #333;
  font-size: 14px;
  margin: 5px 0;
  width: 90%;
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.groupbody .groupdetails .datahere {
  font-size: 12px;
  margin: 5px 0;
  color: #333;
  font-weight: 400;
}
.groupbody .groupdetails .edit {
  margin-left: 5px;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.groupbody .groupdetails .edit:hover {
  background-color: rgba(202, 55, 60, 0.1254901961);
}
.groupbody .groupdetails .edit svg {
  width: 14px;
  height: 14px;
}

.userprofileimg .groupbody .groupdetails .cn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 0px;
}

.flip-card {
  width: 100%;
  height: 230px;
  perspective: 1000px;
  font-family: sans-serif;
  position: relative;
  z-index: 1;
  margin-bottom: 15px;
}
.flip-card:hover .flipbtn {
  color: #66666e;
}
.flip-card .flip-card-inner {
  border: 1px solid #fff;
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  border-radius: 5px;
}
.flip-card .flip-card-inner:hover {
  border-color: rgba(202, 55, 60, 0.3137254902);
}

.flip-card.backside .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-back {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 1rem;
}

.flip-card-back {
  padding: 10px 5px;
  justify-content: center;
}

.groupcardback .grpImagName {
  font-size: 12px;
}
.groupcardback .backdetails {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
}
.groupcardback .backdetails svg {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}
.groupcardback .backdetails .grouplogo {
  background: rgba(223, 225, 235, 0.4);
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  padding: 7px;
  outline: 1px solid grey;
  outline-offset: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.groupcardback .backdetails .grouplogo p {
  margin: 0;
  font-size: 12px;
}
.groupcardback .backdetails .grouplogo img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.groupcardback .backdetails .backinfo p {
  margin: -3px 0;
  font-size: 12px;
  color: #333;
  text-align: left;
  font-weight: 500;
}
.groupcardback .backoptions {
  padding: 5px 0;
  margin-top: 15px;
}
.groupcardback .backoptions .optionhere {
  font-size: 13px;
  color: #333;
  width: 100%;
  text-align: left;
  padding: 5px 0 5px 20%;
  cursor: pointer;
}
.groupcardback .backoptions .optionhere i {
  font-size: 15px;
  color: #333;
  padding-right: 10px;
}
.groupcardback .backoptions .optionhere.editone {
  text-align: center;
  margin: 15px 0;
  color: #ca373c;
  cursor: pointer;
  padding: 0;
}

.createbtn {
  background-color: #ca373c;
  color: #fff;
  padding: 0 1rem;
  border: none;
  font-weight: 400;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ca373c;
  transition: 0.3s;
  letter-spacing: 1px;
  height: 40px;
  font-size: 0.8rem;
  min-width: 6rem;
  width: -moz-max-content;
  width: max-content;
}
.createbtn:hover {
  border: 1px solid #d8383f;
  background-color: #d8383f;
  color: #fff;
}
.createbtn.cancel {
  background-color: transparent;
  color: #333333;
  font-weight: 500;
  border: none;
}
.createbtn.cancel:hover {
  background-color: rgba(51, 51, 51, 0.3137254902);
  color: #fff;
}

button.save {
  background-color: #ca373c;
  color: #fff;
  padding: 0 1rem;
  border: none;
  font-weight: 400;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ca373c;
  transition: 0.3s;
  letter-spacing: 1px;
  height: 40px;
  font-size: 0.8rem;
  min-width: 6rem;
}

.createbtn.cancelbtn {
  background-color: #fff;
  color: #333;
  font-weight: 400;
  border: 1px solid #cad1da;
}
.createbtn.cancelbtn:hover {
  background-color: #a3a3a3;
  color: #fff;
}

.substable {
  margin-top: 20px;
}
.substable .subheadlist {
  font-weight: 500;
  font-size: 15px;
  border-radius: 5px;
  background-color: rgba(202, 55, 60, 0.1254901961);
}
.substable .subchildlist {
  font-size: 14px;
  background-color: #ffffff;
  border-radius: 5px;
}
.substable .subchildlist .subapplist {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 2px;
  font-size: 13px;
  font-weight: 400;
}
.substable .subchildlist .subapplist.resource .appicon img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.substable .subchildlist .subapplist .appicon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #ffffff;
  margin-left: -10px;
  background-color: #fff;
}
.substable .subchildlist .subapplist .appicon img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.substable .subchildlist .subapplist p {
  margin: 0;
  font-weight: 400;
  padding-left: 3px;
  max-width: 9rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.appicon:first-child {
  margin-left: 0;
}

.subhiddentable {
  background-color: rgba(255, 255, 255, 0.3137254902);
}
.subhiddentable thead tr th {
  padding-left: 15px;
  font-weight: 500;
}
.subhiddentable tbody tr {
  border-color: transparent;
}
.subhiddentable tbody tr td {
  font-weight: 400;
  padding-left: 15px;
}
.subhiddentable .subtablesub {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 400;
}
.subhiddentable .subtablesub img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  -o-object-fit: contain;
     object-fit: contain;
  background-color: rgba(51, 51, 51, 0.1254901961);
  border-radius: 1px solid #fff;
}

.childtablelist.active {
  max-height: 300px;
  overflow-y: scroll;
  transition: 900ms all ease-out;
  padding: 10px;
  margin-left: 6px;
  background-color: #f3f3f3;
}

.childtablelist {
  max-height: 0px;
  overflow-y: scroll;
  transition: 500ms all ease-in;
}

.childtablelist::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.childtablelist::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.childtablelist::-webkit-scrollbar-thumb {
  background-color: rgba(51, 51, 51, 0.3137254902);
}

.subheadlist i {
  font-size: 1rem;
}

.subchildlist i {
  font-size: 1rem;
}

.Appbox {
  z-index: 2;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 7px;
  border-radius: 5px;
  background-color: #fff;
}
.Appbox.atassign {
  z-index: inherit;
}
.Appbox.headbox {
  background-color: transparent;
  gap: 5px;
}
.Appbox.headbox .appthumb {
  background: transparent;
}
.Appbox.headbox .back svg {
  width: 0.7em;
  height: 0.7em;
}
.Appbox .appthumb {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  border-radius: 5px;
  overflow: hidden;
  background: rgba(47, 58, 121, 0.0705882353);
  padding: 6px;
}
.Appbox .appthumb .forselected {
  display: none;
}
.Appbox .appthumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.Appbox .appdata {
  min-width: 73%;
  max-width: 73%;
}
.Appbox .appdata .apptitle {
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.Appbox .appdata .apptitle.withresource {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0;
  justify-content: start;
}
.Appbox .appdata .apptitle.withresource span {
  color: #ca373c;
  font-size: 12px;
  z-index: 9;
  background-color: transparent;
  font-weight: 500;
  letter-spacing: 0px;
  padding: 0;
}
.Appbox .appdata .apptitle.withresource span:first-child {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: #000;
}
.Appbox .appdata .apptitle p {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.Appbox .appdata .apptitle span {
  background-color: #636363;
  color: #fff;
  font-weight: 300;
  font-size: 11px;
  padding: 0px 10px;
  border-radius: 5px;
  letter-spacing: 1px;
}
.Appbox .appdata .apptitle span.try {
  background-color: rgba(99, 99, 99, 0.5019607843);
}
.Appbox .appdata .appdetails {
  font-size: 13px;
  max-width: 100%;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #545454;
}
.Appbox .appside {
  width: 100%;
  position: relative;
  height: 30px;
  overflow: hidden;
}
.Appbox .appside svg {
  transition: 300ms all ease;
  position: absolute;
  top: 50%;
  left: 50%;
  stroke: rgba(0, 0, 0, 0.5647058824);
  transform: translate(-50%, -50%);
  font-size: 20px;
}
.Appbox .appside svg:first-child {
  top: 50%;
  left: -50%;
}
.Appbox .appside svg:last-child {
  opacity: 0.5;
}
.Appbox:hover .appside svg {
  transition: 300ms all ease;
}
.Appbox:hover .appside svg:first-child {
  opacity: 1;
  top: 50%;
  left: 70%;
}
.Appbox:hover .appside svg:last-child {
  left: 150%;
  opacity: 0.5;
}
.Appbox .dropdown button {
  color: #333 !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none;
}
.Appbox .dropdown button::after {
  content: none;
}
.Appbox .dropdown .sidebartopbar.modulelistoptions {
  padding: 10px;
  min-width: 200px;
  width: 200px !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 5px;
  z-index: 9;
}
.Appbox .dropdown span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 5px 10px;
  cursor: pointer;
  margin: 0;
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  border-radius: 5px;
}
.Appbox .dropdown span:hover {
  background: rgba(202, 55, 60, 0.2509803922) 0% 0% no-repeat;
}
.Appbox .dropdown span svg {
  width: 15px;
  height: 15px;
}

.Appbox.selected .appthumb {
  position: relative;
  overflow: hidden;
}
.Appbox.selected .appthumb img {
  z-index: 1;
}
.Appbox.selected .appthumb .forselected {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  min-width: 100%;
  background-color: rgba(51, 51, 51, 0.5647058824);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Appbox.selected .appthumb .forselected svg {
  min-width: 60%;
  min-height: 60%;
  fill: #fff;
  opacity: 1;
}

.pagetitletext {
  font-weight: 500;
  font-size: 15px;
  margin: 0;
}

.validitymodal .modal-content {
  padding: 5px 10px;
  max-width: 385px;
  min-height: 540px;
}

.validhead {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.validhead .pagetitletext {
  width: -moz-min-content;
  width: min-content;
  white-space: nowrap;
}
.validhead .line {
  width: 100%;
  height: 2px;
  background-color: rgba(51, 51, 51, 0.3137254902);
}

.validity .Appbox .appdata {
  width: 75%;
  max-width: 75%;
}

.validheader {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.validheader .cancel {
  font-size: 17px;
  font-weight: 400;
  color: #636363;
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
}
.validheader .valid {
  width: -moz-max-content;
  width: max-content;
  font-size: 18px;
  font-weight: 600 !important;
  color: #333333;
}
.validheader .done {
  cursor: pointer;
  width: -moz-max-content;
  width: max-content;
  font-size: 17px;
  font-weight: 400;
  color: #333333;
}

.validbody {
  min-height: 480px;
  padding: 10px 15px;
}
.validbody .validtitle {
  font-size: 15px;
  color: #333;
  padding-bottom: 10px;
  font-weight: 600;
}
.validbody .nav-tabs {
  border: none;
  background: none;
  gap: 20px;
}
.validbody .nav-tabs .nav-item button {
  border-radius: 5px;
  border: none;
  background-color: #e4e4e4;
  color: #333333 !important;
  font-size: 12px;
  padding: 5px 13px;
  font-weight: 400;
  letter-spacing: 1px;
}
.validbody .nav-tabs .nav-item button.active {
  background-color: #7b7b7b;
  color: #f7f7f7 !important;
}

.validbodychild .nav-tabs .nav-item button {
  font-size: 12px !important;
}

p.grpImagName {
  width: 100%;
  height: 100%;
  display: block;
  font-size: 22px;
  font-weight: 500;
}

.quizlabel span {
  font-size: 14px !important;
}

.quizlabel {
  padding: 0 !important;
  margin: 0 !important;
}

.quizlabel span:first-child {
  display: none !important;
}

.quizlabel span.Mui-checked + span {
  background-color: #ca373c;
  color: #fff;
}

.quizlabel span {
  padding: 5px 13px !important;
  border-radius: 5px;
  background-color: rgba(164, 163, 163, 0.1254901961);
  color: #333333;
  font-size: 15px;
}

.outline-none {
  outline: none !important;
}

.userprofileimg .grouplogo {
  overflow: visible;
  outline-offset: 0px !important;
}
.userprofileimg .grouplogo img {
  border-radius: 50%;
  padding: 0px !important;
  -o-object-fit: cover;
     object-fit: cover;
}
.userprofileimg .cn {
  font-size: 15px !important;
}

.searchinconssd .form-control:focus {
  box-shadow: none;
}

.searchfields .form-control:focus-visible {
  box-shadow: none;
  border-color: rgba(51, 51, 51, 0.3137254902);
}

.appscroll .apphead {
  font-weight: 500;
  color: #333333;
  font-size: 15px;
}
.appscroll .searchfields .form-control {
  background-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
}

.applistparent {
  border-left: 1px solid rgba(51, 51, 51, 0.1882352941);
  padding: 0px 30px 0 10px;
}

.applist {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  min-height: 25vh;
}
.applist span {
  padding: 5px 0px 5px 30px;
  font-size: 14px;
  color: rgba(51, 51, 51, 0.3137254902);
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
}
.applist span:hover {
  color: #333333;
}
.applist span.active {
  color: #333333;
  position: relative;
}
.applist span.active::after {
  content: "";
  content: "";
  position: absolute;
  top: 41%;
  left: -13px;
  transform: translate(-50%, 50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid #666666;
  transform: rotate(90deg);
}

.alertmodal .modal-dialog {
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.filterhead button {
  background-color: transparent;
  color: #ca373c;
  border-bottom: 1px;
  border-color: #ca373c;
  border: none;
  padding: 2px 5px;
  font-size: 13px;
  border-radius: 5px;
}

.form-check-input {
  border-color: #ca373c;
}

.reset-page-btn {
  border: none;
  text-align: center;
  width: 100%;
  border-radius: 5px;
  background-color: #ca353b;
  color: #fff;
  padding: 10px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
.reset-page-btn .loader-btn {
  display: flex;
  align-items: center;
}
.reset-page-btn .bar {
  display: inline-block;
  width: 3px;
  height: 15px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  animation: scale-up4 1s linear infinite;
}
.reset-page-btn .bar:nth-child(2) {
  height: 25px;
  margin: 0 5px;
  animation-delay: 0.25s;
}
.reset-page-btn .bar:nth-child(3) {
  animation-delay: 0.5s;
}
@keyframes scale-up4 {
  20% {
    background-color: white;
    transform: scaleY(1.5);
  }
  40% {
    transform: scaleY(1);
  }
}

.Coming-Soon {
  color: #1e226b;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}

.Coming-Soon.beta {
  color: #187800;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  width: 12%;
}

.Appbox.comingsoon {
  background-color: #ebebeb;
}

.Appbox.beta {
  background-color: #fff;
}

.createbtn,
.createbtn-sm {
  background-color: #ca373c;
  border: 1px solid #ca373c;
  border-radius: 5px;
  box-shadow: none;
  color: #fff;
  font-weight: 400;
  letter-spacing: 1px;
  transition: 0.3s;
}

.createbtn-sm.cancel {
  background-color: initial;
  border: none;
  color: #333;
  font-weight: 400;
}

.createbtn-sm:hover {
  background-color: #d8383f;
  border: 1px solid #d8383f;
  color: #fff;
}

.createbtn-sm.cancel:hover {
  background-color: rgba(51, 51, 51, 0.314);
  color: #fff;
}

.password-change-alert {
  padding: 15px 10px;
  color: #377e5d;
  background-color: rgba(25, 135, 84, 0.1019607843);
  border: 1px solid #62b28d;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -moz-max-content;
  width: max-content;
  padding-right: 45px;
  position: absolute;
  margin-bottom: 20px;
  top: -70px;
  font-size: 13.5px;
}
.password-change-alert .close-alert {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.password-change-alert .close-alert svg {
  width: 1.5em;
  height: 1.5em;
}

.loginPage h6 {
  font-weight: 400;
}

.groupbody .groupdetails .gn {
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  border-radius: 3px;
  display: -webkit-box;
  font-size: 11px;
  font-weight: 400;
  font-weight: 500;
  margin: 2px 0;
  overflow: hidden;
  padding: 2px 10px;
  text-overflow: ellipsis;
  width: -moz-max-content;
  width: max-content;
}

.createbtn-sm {
  font-size: 11px !important;
  padding: 2px 5px;
  border: none;
}

.createbtn-sm:hover {
  border: none;
}

.onload-section {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
}
.onload-section .active-overlay {
  width: 60vw;
  aspect-ratio: 1.73/1;
  padding: 10px;
  background-color: #ccd4d4;
  aspect-ratio: 1.73/1;
  position: relative;
  max-width: 1400px;
}
.onload-section .active-overlay iframe {
  width: 100%;
  height: 100%;
}
.onload-section .active-overlay .close-btn {
  position: absolute;
  z-index: 999999;
  top: 3%;
  cursor: pointer;
  right: 2%;
  background: rgba(0, 0, 0, 0.3098039216);
  border-radius: 50%;
}
.onload-section .active-overlay .close-btn svg {
  width: 1.7rem;
  height: 1.7rem;
  fill: #fff;
}
.onload-section .active-overlay .overlay-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
}
.onload-section .info-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ca373c;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  max-width: 33rem;
  gap: 1rem;
  padding: 2rem 1rem;
  filter: drop-shadow(-4px 7px 12px rgba(0, 0, 0, 0.3764705882));
}
.onload-section .info-box img {
  width: 9.5rem;
  max-width: 9.5rem;
}
.onload-section .info-box h6 {
  font-size: 1.3rem;
  font-weight: 600;
}
.onload-section .info-box p {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}
.onload-section .info-box button {
  background-color: #ca373c;
  font-weight: 500;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
}

.showoverlay {
  background-color: rgba(0, 0, 0, 0.4392156863);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
}

.tsc-smartplayer {
  background-color: rgba(0, 0, 0, 0);
}

.tsc-smartplayer div.video-div {
  scale: 1.15;
}

.tsc-smartplayer video {
  width: 102%;
  height: 102%;
}

.logo-title a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.logo-title a p {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 0.9rem;
  color: #333;
}

.PopupModal .modal-dialog {
  width: 60vw;
  max-width: 60rem;
}
.PopupModal .modal-dialog .modal-content {
  border-radius: 15px;
  overflow: hidden;
}
.PopupModal .pro-detail-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: row;
  border-radius: 15px;
  padding: 0rem;
  aspect-ratio: 2/1.2;
  background-color: #0d0d0d;
}
.PopupModal .pro-detail-box .close-icon {
  position: absolute;
  top: 1%;
  right: 0.6%;
  transition: background-color 0.1s linear, border-color 0.1s linear, color 0.1s linear;
  max-height: 32px;
  max-width: 32px;
  min-height: 32px;
  min-width: 32px;
  background-color: rgba(0, 0, 0, 0.1882352941);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.PopupModal .pro-detail-box .close-icon svg {
  fill: #000;
  stroke: rgba(0, 0, 0, 0.3137254902);
}
.PopupModal .pro-detail-box .detail-side {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background: #fff;
  width: 50%;
  border-top-left-radius: 15px;
  height: 100%;
}
.PopupModal .pro-detail-box .detail-side .text-parent {
  padding: 2rem;
}
.PopupModal .pro-detail-box .detail-side .text-parent .pro-btn {
  background-color: #ca373c;
  font-weight: 400;
  color: #fff;
  border-radius: 5px;
  border: none;
  box-shadow: none;
  outline: none;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  width: 100%;
  text-align: center;
}
.PopupModal .pro-detail-box .detail-side .text-parent .text-detail h5 {
  font-weight: 800;
  color: #000;
  font-size: 2.3rem;
  padding-bottom: 1rem;
  margin-bottom: 0;
}
.PopupModal .pro-detail-box .detail-side .text-parent .text-detail h6 {
  font-size: 1.15rem;
  padding-bottom: 1rem;
  margin-bottom: 0;
  color: #686868;
}
.PopupModal .pro-detail-box .detail-side .text-parent .text-list-detail ul {
  padding-left: 1rem;
}
.PopupModal .pro-detail-box .detail-side .text-parent .text-list-detail ul li {
  list-style: none;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  padding-bottom: 1rem;
  position: relative;
}
.PopupModal .pro-detail-box .detail-side .text-parent .text-list-detail ul li strong {
  font-weight: 500;
  color: #ca373c;
}
.PopupModal .pro-detail-box .detail-side .text-parent .text-list-detail ul li::before {
  display: inline-block;
  /* font: normal normal normal 1em / 1 "Nucleo"; */
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "*";
  font-size: 30px;
  color: #ca373c;
  position: absolute;
  left: -18px;
  top: -5px;
  font-weight: 500;
}
.PopupModal .media-side {
  width: 50%;
  min-height: 100%;
  position: relative;
}
.PopupModal .media-side img {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem 1rem 2rem;
  background: #f2f2f2;
  width: 100%;
}
.bottom .company-text {
  font-size: 0.75rem;
  color: #333333;
  font-weight: 500;
}
.bottom .company-logos {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1.5rem;
}
.bottom .company-logos img {
  width: 2rem;
  height: 2rem;
  -o-object-fit: contain;
     object-fit: contain;
}

.expand-shrink {
  width: 5rem;
  cursor: pointer;
  display: block;
}
.expand-shrink svg {
  width: 1rem;
  height: 1rem;
  stroke: #333;
}

.title-row {
  height: 40px;
  min-height: 40px;
}

.userprofileimg .grouplogo {
  position: relative;
}

.userprofileimg .grouplogo .userActive {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: #4CAF50;
  border: 2px solid #fff;
}

.userprofileimg .grouplogo .userInactive {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: #818181;
  border: 2px solid #fff;
}

.groupbody .grouplogo .userInactive {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: #818181;
  border: 2px solid #fff;
}

.groupbody .grouplogo .userActive {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: #4CAF50;
  border: 2px solid #fff;
}

.userprofileimg .grouplogo .active {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: #4CAF50;
  border: 2px solid #fff;
}

.userprofileimg .grouplogo .active {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #b3b3b3;
}

.list-here {
  display: grid;
  gap: 1rem;
  --u_rg: 1.5rem;
  --u_cg: 1.5rem;
  grid-template-columns: repeat(var(--cols), 1fr);
  justify-items: stretch;
  justify-content: center;
  align-content: center;
  margin-top: 1rem;
  --cols: 1;
}
.list-here .application-card {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: 300ms all;
}
.list-here .application-card:hover {
  box-shadow: 0px 0px 7px rgba(202, 55, 60, 0.46);
  transition: 300ms all;
}
.list-here .application-card .application-logo-bg {
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 2.25/1.25;
  background: rgba(0, 0, 0, 0.3137254902);
  transition: 250ms all;
  padding: 0;
  overflow: hidden;
  position: relative;
}
.list-here .application-card .application-logo-bg.locked-content {
  background: rgba(0, 0, 0, 0.8);
}
.list-here .application-card .application-logo-bg.default-bg .default-img-centered {
  max-width: 50%;
  mix-blend-mode: multiply;
  -o-object-fit: contain;
     object-fit: contain;
}
.list-here .application-card .application-logo-bg.loading {
  background: linear-gradient(45deg, rgba(202, 53, 60, 0.3137254902), rgba(47, 58, 123, 0.3137254902) 60%);
  transition: 250ms all;
  background-size: 800% 800%;
  animation: gradientAnimation 3s ease infinite;
}
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.list-here .application-card .application-logo-bg .default-img-centered {
  width: 100%;
  opacity: 1;
  height: 100%;
  mix-blend-mode: multiply;
  -o-object-fit: cover;
     object-fit: cover;
}
.list-here .application-card .app-logo {
  position: absolute;
  bottom: 10%;
  left: 5%;
  background-color: #fff;
  border-radius: 5px;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.25);
}
.list-here .application-card .app-logo span.in-beta {
  position: absolute;
  bottom: 0;
  background: rgba(51, 51, 51, 0.9215686275);
  width: 100%;
  color: #fff;
  font-size: 0.85rem;
  font-weight: 400;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
}
.list-here .application-card .app-logo span.Locked-app {
  position: absolute;
  bottom: 0;
  background: rgba(51, 51, 51, 0.9215686275);
  width: 100%;
  color: #fff;
  font-size: 0.85rem;
  font-weight: 400;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
}
.list-here .application-card .app-logo img {
  width: 3rem;
  -o-object-fit: contain;
     object-fit: contain;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1450980392));
}
.list-here .application-card .arrow-logo {
  width: 1rem;
  position: absolute;
  top: 7%;
  right: 9%;
}
.list-here .application-card .Locked-app-data {
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  top: 7%;
  right: 3%;
  display: flex;
  color: #fff;
  font-size: 0.85rem;
  align-items: center;
  gap: 5px;
  font-weight: 500;
}
.list-here .application-card .Locked-app-data svg {
  margin-top: -2px;
  stroke: rgba(255, 255, 255, 0.3137254902);
}
.list-here .application-details {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  gap: 0.25rem;
  padding-top: 0.5rem;
}
.list-here .application-details .application-name {
  font-weight: 600;
  font-size: 1rem;
  margin: 0;
}
.list-here .application-details .application-des {
  font-weight: 500;
  font-size: 0.7rem;
  margin: 0;
  text-align: left;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #000;
  display: -webkit-box;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-here .application-details .goto-application {
  border: none;
  background-color: #ca353c;
  font-size: 0.85rem;
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
  margin-top: 0.25rem;
  transition: 250ms all;
}
.list-here .application-details .goto-application:hover {
  background: linear-gradient(45deg, #ca353c, #2F3A79);
  background-size: 400% 400%;
  animation: gradientAnimation 5s ease infinite;
}
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.bdetail {
  width: 100%;
}

.select-all {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  color: #ca353c;
}
.select-all label {
  color: #ca353c;
  color: #ca353c;
  font-size: 0.85rem;
  font-weight: 500;
}
.select-all input {
  margin-right: 0.25rem;
  cursor: pointer;
}

.login-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.login-section .left {
  width: 60%;
}
.login-section .right {
  width: 40%;
}/*# sourceMappingURL=subsstyle.css.map */