/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../fonts/poppins/pxiGyp8kv8JHgFVrLPTufntAKPY.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../fonts/poppins/pxiGyp8kv8JHgFVrLPTucHtA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLFj_Z1JlFc-K.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLFj_Z1xlFQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLDz8Z1JlFc-K.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/poppins/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/poppins/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLDD4Z1JlFc-K.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLDD4Z1xlFQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLBT5Z1JlFc-K.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../fonts/poppins/pxiByp8kv8JHgFVrLBT5Z1xlFQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

$dark: #181818;
$color1: #66666e;
$color2: #9999a1;
$color3: #d7d7dd;
$color4: #ececec;
$color5: #e6e6e9;
$color6: #f3f3f3;
$white: #fff;
$cardbg: #fff;
$SimRed: #ca373c;

body {
  background-color: #f3f3f3 !important;
}

* {
  font-family: 'Poppins', sans-serif !important;
}

.lefrightcolspacing {
  padding: 0px 0.5rem;
}

.filterhere {
  position: relative;
  .nav.nav-tabs {
    width: fit-content;
  }
  button {
    font-size: 15px;
  }
  .nav-link {
    color: #333333;
    position: relative;
    border-top: none;
    border-left: none;
    border-right: none;
    border-color: #c4c4c4;
    font-weight: 400;
    opacity: 50%;
    font-size: 13px;
    &:hover {
      border-color: #ca373c;
      opacity: 1;
    }
    &.active {
      border-color: #ca373c;
      background: none;
      font-weight: 500;
      opacity: 1;
      color: #333333;
    }
    &.active::after {
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      background-color: #ca373c;
      width: 11px;
      height: 7px;
      position: absolute;
      content: '';
      bottom: -4px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.filterparent {
  position: absolute;
  top: 10px;
  right: 10px;
}
.filteroption {
  span {
    display: flex;
    padding: 9px 10px;
    border-radius: 5px;
    background: $SimRed;
    gap: 5px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: max-content;
    svg {
      color: #fff;
      width: 20px;
      cursor: pointer;
      padding-right: 5px;
      margin-top: -2px;
    }

    color: #fff;
    font-size: 14px;
  }
  .midline {
    height: 100%;
    width: 1px;
    background-color: #ffffff8a;
  }
}

.flipbtn {
  position: absolute;
  right: 4%;
  top: 3%;
  color: $color2;
  z-index: 2;
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.groupbody {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: 1;
  .grouplogo {
    // box-shadow: rgba(0, 0, 0, 0.07) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.05) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.02) 0px 2px 1px, rgba(0, 0, 0, 0.03) 0px 4px 2px, rgba(0, 0, 0, 0.04) 0px 8px 4px, rgba(0, 0, 0, 0.04) 0px 16px 8px, rgba(0, 0, 0, 0.04) 0px 32px 16px;
    background: rgb(223 225 235 / 40%);
    max-width: 70px;
    width: 100%;
    height: 70px;
    line-height: 70px;
    border-radius: 50%;
    // outline: 1px solid #2c2c2c50 !important;
    // outline-offset: -5px;
    position: relative;
    overflow: visible;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      // border-radius: 50%;
      padding: 10px;
    }
    p {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 1px;
      margin: 0;
    }
    .activedot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #0bc50b;
      position: absolute;
      bottom: 10%;
      right: 10%;
    }
  }
  .groupdetails {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    .cn {
      font-weight: 500;
      color: #333;
      font-size: 14px;
      margin: 5px 0;
      width: 90%;
      min-height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      // height: 45px;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 1;
      // line-clamp: 1;
      // -webkit-box-orient: vertical;
      // overflow: hidden;
    }
    // .gn {
    //   margin: 5px 0px;
    //   background-color: #ca373c20;
    //   padding: 2px 10px;
    //   color: $SimRed;
    //   font-weight: 400;
    //   font-size: 11px;
    //   width: max-content;
    //   border-radius: 10px;
    //   width: max-content;
    //   height: 100%;
    //   text-overflow: ellipsis;
    //   display: -webkit-box;
    //   -webkit-line-clamp: 1;
    //   line-clamp: 1;
    //   -webkit-box-orient: vertical;
    //   overflow: hidden;
    // }
    .datahere {
      font-size: 12px;
      margin: 5px 0;
      color: #333;
      font-weight: 400;
    }

    .edit {
      margin-left: 5px;
      width: 1.2rem;
      height: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover{
        background-color: #ca373c20;
      }
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}

.userprofileimg .groupbody .groupdetails .cn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-height: 0px;
}

.flip-card {
  // background-color: $cardbg;
  width: 100%;
  height: 230px;
  perspective: 1000px;
  font-family: sans-serif;
  position: relative;
  z-index: 1;
  margin-bottom: 15px;
  // border-radius: 5px;
  // transition: 0.3s;

  &:hover .flipbtn {
    color: $color1;
  }
  .flip-card-inner {
    border: 1px solid #fff;
    background-color: #fff;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    border-radius: 5px;
    &:hover {
      border-color: #ca373c50;
    }
  }
}

.flip-card.backside .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-back {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  // box-shadow: 0 8px 14px 0 rgba(0,0,0,0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  // border: 1px solid coral;
  border-radius: 1rem;
}

.flip-card-back {
  padding: 10px 5px;
  justify-content: center;
}

.groupcardback {
  .grpImagName {
    font-size: 12px;
  }
  .backdetails {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px;
    svg {
      width: 1.25rem;
      height: 1.25rem;
      cursor: pointer;
    }
    .grouplogo {
      background: rgba(223, 225, 235, 0.4);
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      border-radius: 50%;
      padding: 7px;
      outline: 1px solid grey;
      outline-offset: -3px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        margin: 0;
        font-size: 12px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .backinfo {
      p {
        margin: -3px 0;
        font-size: 12px;
        color: #333;
        text-align: left;
        font-weight: 500;
      }
    }
  }
  .backoptions {
    padding: 5px 0;
    margin-top: 15px;
    .optionhere {
      font-size: 13px;
      color: #333;
      width: 100%;
      text-align: left;
      padding: 5px 0 5px 20%;
      cursor: pointer;
      i {
        font-size: 15px;
        color: #333;
        padding-right: 10px;
      }
      &.editone {
        text-align: center;
        margin: 15px 0;
        color: $SimRed;
        cursor: pointer;
        padding: 0;
      }
    }
  }
}

.createbtn {
  background-color: $SimRed;
  color: #fff;
  padding: 0 1rem;
  border: none;
  font-weight: 400;
  border-radius: 5px;
  // font-size: 13px !important;
  box-shadow: none;
  border: 1px solid $SimRed;
  transition: 0.3s;
  letter-spacing: 1px;
  height: 40px;
  font-size: 0.8rem;
  min-width: 6rem;
  width: max-content;
  &:hover {
    border: 1px solid #d8383f;
    background-color: #d8383f;
    color: #fff;
  }

  &.cancel {
    background-color: transparent;
    color: #333333;
    font-weight: 500;
    border: none;
    &:hover {
      background-color: #33333350;
      color: #fff;
    }
  }
}

button.save {
  background-color: $SimRed;
  color: #fff;
  padding: 0 1rem;
  border: none;
  font-weight: 400;
  border-radius: 5px;
  // font-size: 13px !important;
  box-shadow: none;
  border: 1px solid $SimRed;
  transition: 0.3s;
  letter-spacing: 1px;
  height: 40px;
  font-size: 0.8rem;
  min-width: 6rem;
}

.createbtn.cancelbtn {
  background-color: #fff;
  color: #333;
  font-weight: 400;
  border: 1px solid #cad1da;
  &:hover {
    background-color: #a3a3a3;
    color: #fff;
  }
}

.substable {
  margin-top: 20px;
  .subheadlist {
    font-weight: 500;
    font-size: 15px;
    border-radius: 5px;
    background-color: #ca373c20;
  }
  .subchildlist {
    font-size: 14px;
    background-color: #ffffff;
    border-radius: 5px;
    .subapplist {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 2px;
      font-size: 13px;
      font-weight: 400;
      &.resource{
        .appicon {
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        
      }
      .appicon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #ffffff;
        margin-left: -10px;
        background-color: #fff;
        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
      p {
        margin: 0;
        font-weight: 400;
        padding-left: 3px;
        max-width: 9rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;  
      }
    }
  }
}

.appicon:first-child {
  margin-left: 0;
}

.subhiddentable {
  background-color: #ffffff50;
  thead {
    tr {
      th {
        padding-left: 15px;
        font-weight: 500;
      }
    }
  }
  tbody {
    tr {
      border-color: transparent;
      td {
        font-weight: 400;
        padding-left: 15px;
      }
    }
  }
  .subtablesub {
    display: flex;
    align-items: center;
    gap: 10px;
    
    img {
      width: 40px;
      height: 40px;
      border-radius: 5px;
      object-fit: contain;
      background-color: #33333320;
      border-radius: 1px solid #fff;
    }
    font-weight: 400;
  }
}

.childtablelist.active {
  max-height: 300px;
  overflow-y: scroll;
  transition: 900ms all ease-out;
  padding: 10px;
  margin-left: 6px;
  background-color: #f3f3f3;
}

.childtablelist {
  max-height: 0px;
  overflow-y: scroll;
  transition: 500ms all ease-in;
}

.childtablelist::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #f5f5f5;
}

.childtablelist::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.childtablelist::-webkit-scrollbar-thumb {
  background-color: #33333350;
  // border: 2px solid #555555;
}

.subheadlist i {
  font-size: 1rem;
}

.subchildlist i {
  font-size: 1rem;
}

.Appbox {
  z-index: 2;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 7px;
  border-radius: 5px;
  background-color: #fff;
  &.atassign {
    z-index: inherit;
  }
  &.headbox {
    background-color: transparent;
    gap: 5px;
    .appthumb {
      background: transparent;
    }
    .back {
      svg {
        width: 0.7em;
        height: 0.7em;
      }
    }
  }
  &:hover {
    // background-color: #f3f3f3;
  }
  .appthumb {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
    border-radius: 5px;
    overflow: hidden;
    background: #2f3a7912;
    padding: 6px;
    .forselected {
      display: none;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .appdata {
    min-width: 73%;
    max-width: 73%;
    .apptitle {
      padding-bottom: 5px;
      display: flex;
      align-items: center;
      gap: 10px;
      &.withresource {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 0;
        justify-content: start;
        span {
          color: #ca373c;
          font-size: 12px;
          z-index: 9;
          background-color: transparent;
          font-weight: 500;
          letter-spacing: 0px;
          padding: 0;
          &:first-child {
            font-size: 14px;
            font-weight: 500;
            margin: 0;
            color: #000;
          }
        }
      }
      p {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
      }
      span {
        background-color: #636363;
        color: #fff;
        font-weight: 300;
        font-size: 11px;
        padding: 0px 10px;
        border-radius: 5px;
        letter-spacing: 1px;
        &.try {
          background-color: #63636380;
        }
      }
    }
    .appdetails {
      font-size: 13px;
      max-width: 100%;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #545454;
    }
  }
  .appside {
    width: 100%;
    position: relative;
    height: 30px;
    overflow: hidden;
    svg {
      transition: 300ms all ease;
      position: absolute;
      top: 50%;
      left: 50%;
      stroke: #00000090;
      transform: translate(-50%, -50%);
      font-size: 20px;
      &:first-child {
        top: 50%;
        left: -50%;
      }
      &:last-child {
        opacity: 0.5;
      }
    }
  }
  &:hover {
    .appside {
      svg {
        transition: 300ms all ease;
        &:first-child {
          opacity: 1;
          top: 50%;
          left: 70%;
        }
        &:last-child {
          left: 150%;
          opacity: 0.5;
        }
      }
    }
  }

  .dropdown {
    button {
      color: #333 !important;
      background-color: transparent !important;
      border: none !important;
      box-shadow: none;
      &::after {
        content: none;
      }
    }
    .sidebartopbar.modulelistoptions {
      padding: 10px;
      min-width: 200px;
      width: 200px !important;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 5px;
      z-index: 9;
    }
    span {
      &:hover {
        background: #ca373c40 0% 0% no-repeat;
      }
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      padding: 5px 10px;
      cursor: pointer;
      margin: 0;
      font-weight: 400;
      color: #000000;
      font-size: 14px;
      border-radius: 5px;
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.Appbox.selected {
  // background-color: #f3f3f3;
  .appthumb {
    position: relative;
    overflow: hidden;
    img {
      z-index: 1;
    }
    .forselected {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      height: 100%;
      min-width: 100%;
      background-color: #33333390;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        min-width: 60%;
        min-height: 60%;
        fill: #fff;
        opacity: 1;
      }
    }
  }
}

.pagetitletext {
  font-weight: 500;
  font-size: 15px;
  margin: 0;
}

.validitymodal {
  .modal-content {
    padding: 5px 10px;
    max-width: 385px;
    min-height: 540px;
  }
}

.validhead {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  .pagetitletext {
    width: min-content;
    white-space: nowrap;
  }
  .line {
    width: 100%;
    height: 2px;
    background-color: #33333350;
  }
}

.validity .Appbox .appdata {
  width: 75%;
  max-width: 75%;
}

.validheader {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .cancel {
    font-size: 17px;
    font-weight: 400;
    color: #636363;
    width: max-content;
    cursor: pointer;
  }
  .valid {
    width: max-content;
    font-size: 18px;
    font-weight: 600 !important;
    color: #333333;
  }
  .done {
    cursor: pointer;
    width: max-content;
    font-size: 17px;
    font-weight: 400;
    color: #333333;
  }
}

.validbody {
  min-height: 480px;
  padding: 10px 15px;
  .validtitle {
    font-size: 15px;
    color: #333;
    padding-bottom: 10px;
    font-weight: 600;
  }
  .nav-tabs {
    border: none;
    background: none;
    gap: 20px;

    .nav-item {
      button {
        border-radius: 5px;
        border: none;
        background-color: #e4e4e4;
        color: #333333 !important;
        font-size: 12px;
        padding: 5px 13px;
        font-weight: 400;
        letter-spacing: 1px;
        &.active {
          background-color: #7b7b7b;
          color: #f7f7f7 !important;
        }
      }
    }
  }
}

.validbodychild {
  .nav-tabs {
    .nav-item {
      button {
        font-size: 12px !important;
      }
    }
  }
}

p.grpImagName {
  width: 100%;
  height: 100%;
  display: block;
  font-size: 22px;
  font-weight: 500;
}

.quizlabel span {
  font-size: 14px !important;
}
.quizlabel {
  padding: 0 !important;
  margin: 0 !important;
}
.quizlabel span:first-child {
  display: none !important;
}
.quizlabel span.Mui-checked + span {
  background-color: #ca373c;
  color: #fff;
}
.quizlabel span {
  padding: 5px 13px !important;
  border-radius: 5px;
  background-color: #a4a3a320;
  color: #333333;
  font-size: 15px;
}

.outline-none {
  outline: none !important;
}

.userprofileimg {
  .grouplogo {
    overflow:visible;
    outline-offset: 0px !important;
    img {
      border-radius: 50%;
      padding: 0px !important;
      object-fit: cover;
    }
  }

  .cn {
    font-size: 15px !important;
  }
}

.searchinconssd {
  .form-control:focus {
    box-shadow: none;
  }
}

.searchfields .form-control:focus-visible {
  box-shadow: none;
  border-color: #33333350;
}

.appscroll {
  .apphead {
    font-weight: 500;
    color: #333333;
    font-size: 15px;
  }
  .searchfields .form-control {
    background-color: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
}

.applistparent {
  border-left: 1px solid #33333330;
  padding: 0px 30px 0 10px;
}

.applist {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  // border-left: 2px solid #c4c4c4;
  min-height: 25vh;
  span {
    padding: 5px 0px 5px 30px;
    font-size: 14px;
    color: #33333350;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      color: #333333;
    }
    &.active {
      color: #333333;
      position: relative;
      &::after {
        content: '';
        content: '';
        position: absolute;
        top: 41%;
        left: -13px;
        transform: translate(-50%, 50%);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 6px solid #666666;
        transform: rotate(90deg);
      }
    }
  }
}

.alertmodal .modal-dialog {
  max-width: fit-content;
}

.filterhead {
  button {
    background-color: transparent;
    color: #ca373c;
    border-bottom: 1px;
    border-color: #ca373c;
    border: none;
    padding: 2px 5px;
    font-size: 13px;
    border-radius: 5px;
  }
}

.form-check-input {
  border-color: #ca373c;
}

.reset-page-btn {
  border: none;
  text-align: center;
  width: 100%;
  border-radius: 5px;
  background-color: #ca353b;
  color: #fff;
  padding: 10px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  .loader-btn {
    display: flex;
    align-items: center;
  }
  .bar {
    display: inline-block;
    width: 3px;
    height: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    animation: scale-up4 1s linear infinite;
  }
  .bar:nth-child(2) {
    height: 25px;
    margin: 0 5px;
    animation-delay: 0.25s;
  }
  .bar:nth-child(3) {
    animation-delay: 0.5s;
  }
  @keyframes scale-up4 {
    20% {
      background-color: #ffff;
      transform: scaleY(1.5);
    }
    40% {
      transform: scaleY(1);
    }
  }
}

.Coming-Soon {
  color: #1e226b;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}

.Coming-Soon.beta {
  color: #187800;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  width: 12%;
}

.Appbox.comingsoon {
  background-color: #ebebeb;
}

.Appbox.beta {
  background-color: #fff;
}

.createbtn,
.createbtn-sm {
  background-color: #ca373c;
  border: 1px solid #ca373c;
  border-radius: 5px;
  box-shadow: none;
  color: #fff;
  font-weight: 400;
  letter-spacing: 1px;
  transition: 0.3s;
}

.createbtn-sm.cancel {
  background-color: initial;
  border: none;
  color: #333;
  font-weight: 400;
}

.createbtn-sm:hover {
  background-color: #d8383f;
  border: 1px solid #d8383f;
  color: #fff;
}

.createbtn-sm.cancel:hover {
  background-color: rgba(51, 51, 51, 0.314);
  color: #fff;
}

.password-change-alert {
  padding: 15px 10px;
  color: #377e5d;
  background-color: rgba(25, 135, 84, 0.1019607843);
  border: 1px solid #62b28d;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding-right: 45px;
  position: absolute;
  margin-bottom: 20px;
  top: -70px;
  font-size: 13.5px;
  .close-alert {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    svg {
      width: 1.5em;
      height: 1.5em;
    }
  }
}

.loginPage {
  h6 {
    font-weight: 400;
  }
}

.groupbody .groupdetails .gn {
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  border-radius: 3px;
  display: -webkit-box;
  font-size: 11px;
  font-weight: 400;
  font-weight: 500;
  // height: 100%;
  margin: 2px 0;
  overflow: hidden;
  padding: 2px 10px;
  text-overflow: ellipsis;
  width: -moz-max-content;
  width: -webkit-max-content;
  width: max-content;
}

.createbtn-sm {
  font-size: 11px !important;
  padding: 2px 5px;
  border: none;
}

.createbtn-sm:hover {
  border: none;
}


.onload-section{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  flex-direction: column;

  .active-overlay{
    width: 60vw;
    aspect-ratio: 1.73 / 1;
    padding: 10px;
    background-color: #ccd4d4;
    aspect-ratio: 1.73 / 1;
    position: relative;
    max-width: 1400px;
    iframe{
      width: 100%;
      height: 100%;
    }
    .close-btn{
      position: absolute;
      z-index: 999999;
      top: 3%;
      cursor: pointer;
      right: 2%;
      background: #0000004f;
      border-radius: 50%;
      svg{
        width: 1.7rem;
        height: 1.7rem;
        fill: #fff;
      }
    }
    .overlay-shadow {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  .info-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #ca373c;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
    max-width: 33rem;
    gap: 1rem;
    padding: 2rem 1rem;
    filter: drop-shadow(-4px 7px 12px #00000060);
    img{
      width: 9.5rem;
      max-width: 9.5rem;
    }
    h6{
      font-size: 1.3rem;
      font-weight: 600;
    }
    p{
      font-size: 1rem;
      font-weight: 500;
      text-align: center;
    }
    button{
      background-color: #ca373c;
      font-weight: 500;
      color: #fff;
      padding: 0.5rem 1rem;
      border: none;
    }
  }
}

.showoverlay {
  background-color: #00000070;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
}

.tsc-smartplayer{
  background-color: #00000000;
}

.tsc-smartplayer div.video-div{
  scale: 1.15;
}

.tsc-smartplayer video {
  width: 102%;
  height: 102%;
}

.logo-title a{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  p{
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 0.90rem;
    color: #333;
  }
}

.PopupModal{
  .modal-dialog{
  width: 60vw;
  max-width: 60rem;
  .modal-content{
    border-radius: 15px;
    overflow: hidden;
  }
  }
  
  
  .pro-detail-box{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: row;
    border-radius: 15px;
    padding: 0rem;
    aspect-ratio: 2/1.2;
    // background-color: #ca373c2e;
    background-color: #0d0d0d;
    // .upper{
    //   width: 100%;
    //   height: 100%;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   position: relative;
    //   background-color: #f2f2f2;
    //   border-top-left-radius: 15px;
    //   border-top-right-radius: 15px;
    .close-icon{
      position: absolute;
      top: 1%;
      right: 0.6%;
      transition: background-color .1s linear, border-color .1s linear, color .1s linear;
      max-height: 32px;
      max-width: 32px;
      min-height: 32px;
      min-width: 32px;
      background-color: #00000030;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg{
        fill: #000;
        stroke: #00000050;
      }
    }
      .detail-side{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        background: #fff;
        width: 50%;
        border-top-left-radius: 15px;
        height: 100%;
        .text-parent{
          padding: 2rem;
      .pro-btn{
        background-color: #ca373c;
        font-weight: 400;
        color: #fff;
        border-radius: 5px;
        border: none;
        box-shadow: none;
        outline: none;
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
        width: 100%;
        text-align: center;
      }
      .text-detail{
        h5{
          font-weight: 800;
          color: #000;
          font-size: 2.3rem;
          padding-bottom: 1rem;
          margin-bottom: 0;
        }
        h6{
          font-size: 1.15rem;
          padding-bottom: 1rem;
          margin-bottom: 0;
          color: #686868;
        }
      }
      .text-list-detail{
        ul{
          
          padding-left: 1rem;
          li {
          list-style: none;
          // &::marker{
          //   color: #ca373c;
          //   background-color: #ca373c;
          // }
          font-size: 1rem;
          font-weight: 500;
          color: #000;
          padding-bottom: 1rem;
          position: relative;
          strong{
            font-weight: 500;
            color: #ca373c;
          }
          &::before{
            display: inline-block;
            /* font: normal normal normal 1em / 1 "Nucleo"; */
            text-transform: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "*";
            font-size: 30px;
            color: #ca373c;
            position: absolute;
            left: -18px;
            top: -5px;
            font-weight: 500;
          }
        }
      }
      }
    }
    }
    // }
    }
    .media-side{
      width: 50%;
      min-height: 100%;
      position: relative;
      // background-image: url(../../images/pro/Dashboard.jpg);
      img{
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
      }
    }
  }

  .bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem 1rem 2rem;
    background: #f2f2f2;
    width: 100%;
    .company-text{
      font-size: 0.75rem;
      color: #333333;
      font-weight: 500;
    }
    .company-logos{
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 1.5rem;
      img{
        width: 2rem;
        height: 2rem;
        object-fit: contain;
      }
    }
  }

  .expand-shrink{
    width: 5rem;
    cursor: pointer;
    display: block;
    // height: 1rem;
    svg{
      width: 1rem;
      height: 1rem;
      stroke: #333;
    }
  }

  .title-row{
    height: 40px;
    min-height: 40px;
  }

  .userprofileimg .grouplogo{
    position: relative;
  }

  .userprofileimg .grouplogo .userActive{
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: #4CAF50;    
    border: 2px solid #fff;
  }


  .userprofileimg .grouplogo .userInactive{
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: #818181;    
    border: 2px solid #fff;
  }

  .groupbody .grouplogo .userInactive{
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: #818181;    
    border: 2px solid #fff;
  }

  .groupbody .grouplogo .userActive {
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: #4CAF50;
    border: 2px solid #fff;
}

  .userprofileimg .grouplogo .active{
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: #4CAF50;    
    border: 2px solid #fff;
  }

  .userprofileimg .grouplogo .active{
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: #b3b3b3;
  }

  .list-here{
    display: grid;
    gap: 1rem;
    --u_rg: 1.5rem;
    --u_cg: 1.5rem;
    grid-template-columns: repeat(var(--cols), 1fr);
    justify-items: stretch;
    justify-content: center;
    align-content: center;
    margin-top: 1rem;
    --cols: 1;
    .application-card{
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      text-align: center;
      background-color: #fff;
      border-radius: 5px;
      cursor: pointer;
      transition: 300ms all;
      &:hover{
        box-shadow: 0px 0px 7px rgb(202 55 60 / 46%);
        transition: 300ms all;
      }
      .application-logo-bg{
        border-radius: 5px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        aspect-ratio: 2.25 / 1.25;
        background: #00000050;
        transition: 250ms all;
        padding: 0;
        overflow: hidden;
        &.locked-content{
          background: #000000cc;
        }
        &.default-bg{
          .default-img-centered{
            max-width: 50%;
            // opacity: 0.6;
            mix-blend-mode: multiply;
            object-fit: contain;
          }
        }
        &.loading{
          background: linear-gradient(45deg, #ca353c50, #2F3A7B50 60%);
          transition: 250ms all;
          background-size: 800% 800%;
          animation: gradientAnimation 3s ease infinite;
          @keyframes gradientAnimation {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
         }
        }
        // &:hover{
        //   transition: 250ms all;
        //   background-size: 800% 800%;
        //   animation: gradientAnimation 7s ease infinite;
        //   @keyframes gradientAnimation {
        //     0% {
        //         background-position: 0% 50%;
        //     }
        //     50% {
        //         background-position: 100% 50%;
        //     }
        //     100% {
        //         background-position: 0% 50%;
        //     }
        //  }
        // }
      position: relative;
      .default-img-centered{
        width: 100%;
        opacity: 1;
        height: 100%;
        mix-blend-mode: multiply;
        object-fit: cover;
      }
    }
        .app-logo{
          position: absolute;
          bottom: 10%;
          left: 5%;
          background-color: #fff;
          border-radius: 5px;
          width: 3.5rem;
          height: 3.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          box-shadow: 0 0px 4px rgba(0, 0, 0, .25);
          span.in-beta {
            position: absolute;
            bottom: 0;
            background: #333333eb;
            width: 100%;
            color: #fff;
            font-size: 0.85rem;
            font-weight: 400;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            height: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: 1px;
          }
          span.Locked-app {
            position: absolute;
            bottom: 0;
            background: #333333eb;
            width: 100%;
            color: #fff;
            font-size: 0.85rem;
            font-weight: 400;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            height: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: 1px;
          }
          img{
            width: 3rem;
            object-fit: contain;
            filter: drop-shadow(0px 0px 5px #00000025);
          }
        }
        .arrow-logo{
          width: 1rem;
          position: absolute;
          top: 7%;
          right: 9%;
        }
        .Locked-app-data{
          width: fit-content;
          position: absolute;
          top: 7%;
          right: 3%;
          display: flex;
          color: #fff;
          font-size: 0.85rem;
          align-items: center;
          gap: 5px;
          font-weight: 500;
          svg{
            margin-top: -2px;
            stroke: #ffffff50;
          }
        }
      }
      .application-details{
        display: flex;
        align-items: start;
        justify-content: start;
        flex-direction: column;
        gap: 0.25rem;
        padding-top: 0.5rem;
        .application-name{
          font-weight: 600;
          font-size: 1rem;
          margin: 0;
        }
        .application-des{
          font-weight: 500;
          font-size: 0.70rem;
          margin: 0;
          text-align: left;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          color: #000;
          display: -webkit-box;
          margin: 0;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .goto-application{
          border: none;
          background-color: #ca353c;
          font-size: 0.85rem;
          color: #fff;
          padding: 5px 15px;
          border-radius: 5px;
          margin-top: 0.25rem;
          transition: 250ms all;
          &:hover{
             background: linear-gradient(45deg,#ca353c,#2F3A79);
             background-size: 400% 400%;
             animation: gradientAnimation 5s ease infinite; 
              @keyframes gradientAnimation {
                0% {
                    background-position: 0% 50%;
                }
                50% {
                    background-position: 100% 50%;
                }
                100% {
                    background-position: 0% 50%;
                }
                }
        }
      }
    }
  }



  // .modalheader {
  //   background-color: #ca373c;
  //   color: #fff;
  //   justify-content: space-between;
  // }

  // .modalheader span {
  //   cursor: pointer;
  // }

  // .modalheader span svg {
  //   width: 1.3rem;
  //   height: 1.3rem;
  // }

  // .dropzone-parent{
  //   display: flex;
  //   align-items: center;
  //   justify-content: start;
  //   gap: 0.75rem;
  //   .dropzone{
  //     margin: 0;
  //   }
  //   .dropbox-info-btns {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: start;
  //     align-items: start;
    
  //     p{
  //       font-weight: 500;
  //       color: #333;
  //       margin-bottom: 0.3rem;
  //     }
  //     button{
  //       background-color: rgb(0, 140, 255);
  //       border: none;
  //       border-radius: 5px;
  //       padding: 5px 10px;
  //       color: #fff;
  //       font-size: 0.8rem;
  //       margin-right: 0.5rem;
  //       &.remove{
  //         background-color: #4d4d4d30;
  //         color: #000;
  //       }
  //     }
  //   }
  // }

  .bdetail{
    width: 100%;
  }

  .select-all {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    color: #ca353c;
    label{
      color: #ca353c;
      color: #ca353c;
      font-size: 0.85rem;
      font-weight: 500;
    }
    input{
      margin-right: 0.25rem;
      cursor: pointer;
    }
  }

  .login-section{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    .left{
      width: 60%;
    }
    .right{
      width: 40%;
    }
  }