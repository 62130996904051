
.withbarscroll::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  background-color: #ffffff;
  border-radius: 10px;
}

.withbarscroll::-webkit-scrollbar {
  width: 5px;
  background-color: #ffffff;
  display: block;
}

.withbarscroll::-webkit-scrollbar-thumb {
  background: transparent linear-gradient(91deg, #333333 0%, #767676 42%, #767676 53%, #333333 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 6px;
  box-shadow: 0px 3px 6px #00000029;
  width: 10px;
}

.userinputsearch::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  background-color: #ffffff;
  border-radius: 10px;
}

.userinputsearch::-webkit-scrollbar {
  width: 5px;
  background-color: #ffffff;
  display: block;
}

.userinputsearch::-webkit-scrollbar-thumb {
  background: transparent linear-gradient(91deg, #333333 0%, #767676 42%, #767676 53%, #333333 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 6px;
  box-shadow: 0px 3px 6px #00000029;
  width: 10px;
}

*::-webkit-scrollbar-track
{
  border-radius: 10px ;
  background-color: #f3f3f3 ;
}

*::-webkit-scrollbar
{
  width: 5px !important;
  background-color: #f3f3f3 ;
  display: block;
}

*::-webkit-scrollbar-thumb
{
  border-radius: 10px ;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3) ;
  background-color: #33333370 ;
}


/* * {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}
*::-webkit-scrollbar {
  display: none; 
} */



a {
  text-decoration: none;
  color: #66666e;
}

body {
  position: relative;
  z-index: 1;
}

ul {
  list-style-type: none;
}
ul li {
  list-style-type: none;
}

.header {
  position: fixed;
  width: 100%;
  z-index: 99;
  background-color: #fff;
}
.header hr {
  margin: 0.6rem 0rem;
}
.header .dropdown .dropdown-menu {
  width: 300px;
  padding: 15px;
}
.header .dropdown .dropdown-toggle {
  background: none;
  border: none;
  box-shadow: none;
  color: #444;
}
.header .dropdown .dropdown-toggle::after {
  content: inherit;
}
.header .dropdown .dropdown-toggle:active {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #767676;
}
.header .logo {
  height: 40px;
  max-height: 40px;
}
.header .userdropdown .dropdown-menu {
  min-width: 250px !important;
}
.header .montholyplan {
  margin-bottom: 0px;
  margin-top: 5px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  color: #3d3d3d;
}
.header .montholyplan span {
  color: #ccc;
  cursor: pointer;
}
.header .userprofile {
  padding: 5px;
}
.header .userprofile .profileheading {
  display: flex;
  gap: 5px;
}
.header .userprofile .profileheading h6 {
  margin-bottom: 0px;
  text-transform: capitalize;
  font-size: 14px;
}
.header .userprofile .profileheading p {
  font-size: 12px;
  color: #818181;
  margin-bottom: 5px;
}
.header .userprofile ul {
  list-style-type: none;
}
.header .userprofile ul li a {
  color: #767676;
  text-decoration: none;
  font-size: 0.9rem;
  padding: 4px 0px;
  display: block;
}
.header .userprofile ul li a svg {
  width: 1.2rem;
  height: 1.2rem;
}
.header .search {
  margin-right: 200px;
}
.header .search input {
  width: 450px;
  background-color: #d7d7dd;
  border: none;
}

.form-group {
  margin-bottom: 0.5rem;
}

.form-control {
  border-radius: 4px;
}

.search {
  position: relative;
}
.search span {
  position: absolute;
  top: 1px;
  left: 8px;
}
.search span svg {
  width: 1em;
  height: 1em;
  color: #9999a1;
}
.search input {
  padding-left: 30px;
  padding-top: 0;
  font-size: 13px;
  height: 30px;
  padding-bottom: 0;
}
.search input::-moz-placeholder {
  font-size: 13px;
  color: #d7d7dd;
}
.search input::placeholder {
  font-size: 13px;
  color: #d7d7dd;
}
.search input:focus-visible {
  box-shadow: none;
  border: 1px solid #333;
}
.search input:focus {
  box-shadow: none;
  border: 1px solid #333;
}
.search input:focus-within {
  box-shadow: none;
  border: 1px solid #333;
}

.simulanisapps {
  padding-top: 15px;
}
.simulanisapps ul {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}
.simulanisapps ul li {
  width: 50%;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-bottom: 3px;
}
.simulanisapps ul li img {
  width: 16px;
  -o-object-fit: contain;
  object-fit: contain;
  margin-right: 5px;
}

.userdropdown span {
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  text-transform: capitalize;
}
.userdropdown img {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: #505050;
  padding: 1px;
  margin-left: 5px;
  -o-object-fit: contain;
  object-fit: contain;
}

.wrapper {
  display: flex;
  height: 100%;
  padding-top: 50px;
}

.sidebar {
  max-width: 90px;
  width: 100%;
  text-align: center;
  font-size: 11px;
  padding: 5px 0px;
  background: #fff;
  max-height: 100vh;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  left: 0px;
  top: 57px;
  z-index: 99;
  border-right: 1px solid #ededed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 4rem;

}
.sidebar li {
  margin: 0px 1px;
  padding: 8px 0px;
}
.sidebar a {
  text-decoration: none;
  padding: 2px 3px;
  display: block;
  border-left: 4px solid transparent;
  cursor: pointer;
}
.sidebar a.active span {
  color: #ca373c;
  font-weight: 500;
}
.sidebar a.active span svg path {
  fill: #ca373c;
}
.sidebar a span {
  display: block;
  color: #9999a1;
  line-height: 1.1;
}
.sidebar a span svg {
  width: 20px;
}
.sidebar a span svg path {
  fill: #66666e;
}
.sidebar .active {
  border-color: #ca353b;
}

.logout {
  cursor: pointer;
  text-align: center;
  display: block;
}

.marginright {
  /* padding: 20px; */
  width: 100%;
  /* margin-left: 85px; */
}



.groupdetail {
  gap: 15px;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
}
.groupdetail .businesslogo.user .businesslogoimg {
  padding: 0;
  overflow: hidden;
}
.groupdetail .businesslogo .businesslogoimg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  max-width: 75px;
  max-height: 75px;
  line-height: 75px;
  background-color: rgba(223, 225, 235, 0.4);
  border-radius: 50%;
  padding: 10px;
}
.groupdetail .businesslogo .businesslogoimg img {
  -o-object-fit: contain;
  object-fit: contain;
    width: 100%;
    height: 100%;
    padding: 3px;
}

.groupdetail .businesslogo.user .businesslogoimg img {
  -o-object-fit: contain;
  object-fit: contain;
    width: 100%;
    height: auto;
    padding: 0px;
}
.groupdetail .businesslogo .grpImagName11 {
  margin: 0;
  font-size: 25px;
}
.groupdetail .bdetail h5 {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding-bottom: 1rem;
}
.groupdetail .bdetail h5 svg {
  width: 18px;
  height: 18px;
  /* margin-left: 7px;
  margin-top: -4px; */
}
.groupdetail .bdetail span{
    cursor: pointer;
   width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.groupdetail .bdetail span:hover, .groupdetail .bdetail span.detail-action-btn:hover{
  background-color: #CA373C20;
}

.groupdetail .bdetail h5 .passkey svg{
  transform: rotate(90deg);
}
.groupdetail .bdetail h5 svg path {
  fill: #ca373c;
}
.groupdetail .bdetail p {
  margin-bottom: 0px;
  color: #767676;
  font-size: 0.9rem;
}
.groupdetail .bdetail .groupsuser {
  color: #2b2b2b;
}

.groupdetail .bdetail span:first-child{
  margin-left: 0.25rem;
}

.groupdata .fliplogo {
  width: 35px;
}
.groupdata .fliplogo img {
  width: 30px;
  height: 30px;
  line-height: 30px;
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: 50px;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 7px 1px #ccc;
  padding: 5px;
  margin: 0 auto;
  display: block;
}
.groupdata .company {
  font-size: 15px;
}
.groupdata .email {
  font-size: 13px;
  color: #707070;
}
.groupdata p {
  font-size: 13px;
  margin-bottom: 4px;
  color: #636363;
}
.groupdata p svg {
  width: 0.8em;
  height: 0.8em;
}

.groupcard .groupcont .company {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
  padding: 4px 0px;
}
.groupcard .groupcont .group {
  background-color: #ededed;
  font-size: 10px;
  max-width: 100px;
  width: 100%;
  margin: 0 auto;
  display: block;
  border-radius: 10px;
  padding: 2px 4px;
}
.groupcard .groupcont .gusernumber {
  font-size: 11px;
  text-align: center;
  margin-top: 5px;
}

/* Dropzone.css */
.dropzone {
  position: relative;
  border: 2px dashed #ccc;
  background-color: #f8f8f8;
  text-align: center;
  cursor: pointer;
  width: 5.5rem;
  height: 5.5rem;
  line-height: 5.5rem;
  border-radius: 50%;
  margin: 0 auto;
}

.dropzone p {
  font-size: 16px;
  color: #333333;
}

.dropzone:hover {
  border-color: #aaaaaa;
}

.cursor-pointer {
  cursor: pointer;
}

.dropzonimg {
  filter: grayscale(1);
  opacity: 0.05;
  position: absolute;
  width: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .errormessage {
  color: red;
  line-height: 1.5;
  font-size: 0.75rem;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
} */

.successmessage {
  color: green;
  line-height: 1.5;
  font-size: 0.75rem;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.createform {
  max-height: 55vh;
  /* min-height: 40vh; */
  overflow-y: auto;
  margin: 1rem 0rem;
  padding: 0 0.5rem;
}
.createform p , .select-option-radio p, .form-group p {
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 5px;
  display: block;
  font-weight: 500;
}

.createform p span, .form-group p span{
  color: #ca373c;
}
.select-option-radio label > span{
  padding: 0;
}
.select-option-radio .radio-options{
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
}

.select-option-radio .radio-options label span{
  font-size: 15px;
  color: #333;
  font-weight: 500;
}
.createform input {
  font-size:  1rem;
  color: #333;
  padding: 10px 15px;
  padding-left: 10px;
  border: 1.5px solid #d3d3d3;
  transition: all .3s cubic-bezier(.19,1,.22,1);
  border-radius: 5px;
  height: 18px;
}

.createform .with-select input{
  height: 26px;
}

.createform fieldset {
  border: none !important;
}

.createform input:focus,.createform input:focus-visible,.createform input:focus-within{
  border: 1.5px solid #70707090;
  background: #F8F8FA 0% 0% no-repeat padding-box;
  transition: all .3s cubic-bezier(.19,1,.22,1);
}

.createform input::-moz-placeholder {
  color: #868686;
  font-size: 0.9rem;
}
.createform input::placeholder {
  color: #868686;
  font-size: 0.9rem;
}
.createform::-webkit-scrollbar {
  width: 5px;
}
.createform::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.createform::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}
.createform::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.cardbox {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
  padding: 10px 20px 0px 0px;
  cursor: pointer;
  margin-bottom: 20px;
}
.cardbox h6 {
  font-size: 15px;
  color: #4d4d4d;
  margin-bottom: 5px;
}
.cardbox h6 .accessed,
.cardbox h6 .trynow {
  color: #fff;
  padding: 3px 10px;
  border-radius: 50px;
  font-size: 12px;
}
.cardbox h6 .accessed {
  background: #3b3b3b;
}
.cardbox h6 .trynow {
  background: #979797;
}
.cardbox p {
  font-size: 13px;
  margin-bottom: 0px;
  color: #a7a7a7;
}
.cardbox img {
  width: 45px;
  -o-object-fit: contain;
  object-fit: contain;
}

.tabsproduct {
  display: inline-flex;
}

.tabsproduct.top-updated .nav-item {
  border-radius: 10px;
  /* box-shadow: 0 0px 4px rgba(0, 0, 0, .15); */
  margin: 0 10px;
  margin-left: 0;
}

.tabsproduct.top-updated .nav-item .nav-link {
  align-items: center;
  background: #fff 0 0 no-repeat padding-box;
  border: 2px solid transparent;
  color: #333;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  gap: 12px;
  height: auto;
  padding: 8px 20px;
  border-radius: 5px;
  box-shadow: 0 0px 4px rgba(0, 0, 0, .15);
}

.tabsproduct.top-updated .nav-item .nav-link.active {
  align-items: center;
  background-color: #ffffff !important;
  border: 2px solid #ca373c;
  color: #ca373c;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  gap: 12px;
  height: auto;
  padding: 8px 20px;
  border-radius: 5px;
  box-shadow: 0 0px 4px rgba(0, 0, 0, .15);
}

.tabsproduct.top-updated .nav-item .nav-link:hover {
  border: 2px solid #ca373c90;
}

.tabsproduct.top-updated .nav-item .nav-link::after, .tabsproduct.top-updated .nav-item .nav-link.active::after,.tabsproduct.top-updated .nav-item .nav-link:hover::after{
  content: none;
}

.tabsproduct .nav-item .nav-link {
  color: #9b9b9b;
  font-size: 0.9rem;
  font-weight: 400;
  position: relative;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  text-transform: capitalize;
}
.tabsproduct .nav-item .nav-link:hover {
  border-bottom: 1px solid #ca373c;
}
.tabsproduct .nav-item .nav-link:hover::after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid #ca373c;
}
.tabsproduct .nav-item .nav-link.active,
.tabsproduct .nav-item .show > .nav-link {
  background-color: transparent !important;
  color: #ca373c !important;
  position: relative;
  font-weight: 500;
  border-bottom: 1px solid #ca373c;
  border-radius: 0px;
}
.tabsproduct .nav-item .nav-link.active::after,
.tabsproduct .nav-item .show > .nav-link::after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid #ca373c;
}

.filterbox {
  width: 220px;
  background: antiquewhite;
  position: absolute;
  z-index: 99999;
  height: auto;
  right: 0px;
  top: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 5px;
  padding: 10px;
}
.filterbox .filterhead {
  display: flex;
  justify-content: space-between;
}
.filterbox .filterhead p {
  font-weight: 400;
  font-size: 15px;
  color: #333333;
  margin: 0;
}
.filterbox .filterhead p:first-child {
  color: #333;
  font-weight: 500;
  border-bottom: 2px solid #333;
}
.filterbox .filterhead p:last-child {
  color: #636363;
  font-size: 13px;
}
.filterbox .createdfilter {
  display: flex;
  justify-content: start;
  flex-direction: column;
  margin-top: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1254901961);
  padding-bottom: 10px;
  overflow: hidden;
  height: 26px;
  transition: all 0.4s ease-in-out;
}
.filterbox .createdfilter.createdactive {
  transition: all 0.4s ease-in-out;
  height: 150px;
}
.filterbox .createdfilter svg {
  fill: #9999a1;
  width: 17px;
  height: 17px;
}
.filterbox .createrhead {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.filterbox .createrhead p {
  margin: 0;
  font-size: 13px;
}
.filterbox .createrhead p svg {
  fill: #636363;
}
.filterbox .createddata {
  display: flex;
  align-items: center;
}
.filterbox .createddata .container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  margin-right: 5px;
  width: 17px;
  max-width: 17px;
  margin-top: 1px;
  display: block;
  width: 20px;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  max-width: 20px;
  margin-left: 0;
  user-select: none;
  margin-right: 15px;
  margin-bottom: 0;
  padding: 0;
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
  margin-right: 5px;
  width: 17px;
  max-width: 17px;
  margin-top: 1px;
}
.filterbox .createddata .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.filterbox .createddata .container input:checked ~ .checkmark {
  background-image: linear-gradient(#ca373c, #ca373c);
}
.filterbox .createddata .container input:checked ~ .checkmark:after {
  display: block;
}
.filterbox .createddata .container .checkmark:after {
  top: 20%;
  left: 25%;
  transform: translate(-50%, -50%) !important;
  width: 100%;
  height: 100%;
  border: solid white;
  border-width: 0 0.1em 0.1em 0;
  transform: rotate(45deg) !important;
}
.filterbox .createddata p {
  font-weight: 400 !important;
  margin: 0;
}
.filterbox .createddata .checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 1em;
  width: 1em;
  background: transparent;
  border-radius: 2px;
  box-shadow: rgb(205, 67, 71) 0px 0px 0px 1px;
  width: 13px !important;
  height: 13px !important;
}
.filterbox .createddata .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.filterbox .createdatahere {
  padding: 0px;
  max-height: 170px;
  overflow-y: auto;
}
.filterbox .createdatahere::-webkit-scrollbar {
  width: 5px;
}
.filterbox .createdatahere::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.filterbox .createdatahere::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}
.filterbox .createdatahere::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.filterbox .createdatahere label span {
  font-size: 13px;
  padding: 2px 0 2px 15px;
}
.filterbox .createdatahere label span:nth-child(2) {
  padding-left: 5px;
}
.filterbox .filterfooter {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}
.filterbox .filterfooter p {
  font-size: 12px;
  cursor: pointer;
  margin: 0;
}
.filterbox .filterfooter p:first-child {
  color: #333333;
  font-weight: 500;
  font-size: 11px;
}
.filterbox .filterfooter p:last-child {
  font-size: 12px !important;
  color: #fff;
  background-color: #636363;
  border-radius: 5px;
  padding: 5px 15px;
}
.filterbox .userselection .container {
  display: block;
  width: 20px;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  max-width: 20px;
  margin-left: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  margin-right: 15px;
  margin-bottom: 0;
  padding: 0;
}

.showoverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: transparent;
  z-index: 9999;
}

.filterbtn {
  color: #fff;
  background: #ca373c 0% 0% no-repeat padding-box;
  border: 1px solid #ca373c;
  border-radius: 5px;
  gap: 10px;
  padding: 5px 15px;
  cursor: pointer;
}

.col-md-2 {
  position: relative;
}

.groupcard,
.groupdata {
  transition: transform 1s;
}
.groupcard span,
.groupdata span {
  cursor: pointer;
}

.groupcard {
  transform: rotateY(360deg);
  height: 100%;
}

.groupdata.flipped {
  transform: rotateY(0deg);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.groupdetails .cn svg {
  width: 14px;
  height: 14px;
}
.groupdetails .email {
  font-size: 12px;
  margin-bottom: 0px;
  color: #66666e;
  font-weight: 400;
  line-break: anywhere;
  padding: 0 5px;
}
.groupdetails .post {
  font-size: 12px;
  margin-bottom: 0px;
  padding: 4px 0px;
  color: #181818;
  display: flex;
  /* min-height: 20px; */
  line-break: anywhere;
  padding: 0 5px;
}
.groupdetails .post svg {
  width: 1.1rem;
  height: 1.1rem;
  color: #66666e;
}
.groupdetails .lastaccessed {
  font-size: 11px;
  margin-bottom: 0px;
  font-weight: 600;
  color: #66666e;
}

.rolesaccess .role {
  max-width: 250px;
}
.rolesaccess .prheading {
  margin: 0 auto;
  display: block;
  padding: 0px 60px;
}
.rolesaccess .pprofilebox {
  margin: 0 auto;
  display: block;
  padding: 0px 60px;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
}
.rolesaccess h5 {
  font-size: 16px;
}
.rolesaccess .profileaccescard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e6e6e9;
  padding: 5px 5px 5px 14px;
  border-radius: 4px;
  margin-bottom: 10px;
}
.rolesaccess .profileaccescard p {
  margin-bottom: 0px;
  font-size: 14px;
}
.rolesaccess .role .createnewrole {
  background-color: transparent;
  padding: 0px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
}
.rolesaccess .role .createnewrole svg {
  width: 1.1rem;
  height: 1.1rem;
}
.rolesaccess .role ul {
  margin: 0px;
  margin-top: 15px;
  padding: 0px;
  list-style-type: none;
  font-size: 14px;
}
.rolesaccess .role ul li {
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;
}

.searchfields {
  position: relative;
  max-width: 350px;
  width: 100%;
  min-width: 250px;
}
.searchfields .searchinconssd {
  position: absolute;
  left: 15px;
  top: 7px;
}
.searchfields .searchinconssd svg {
  width: 1em;
  height: 1em;
}
.searchfields .form-control {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 40px;
  line-height: 25px;
  font-size: 14px;
  background-color: #fff;
}

.searchfields.inputfiled .form-control {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  line-height: 25px;
  font-size: 14px;
  background-color: #fff;
}

.searchfields.inputfiled p {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 15px;
}

.csvuploaddownload {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}
.csvuploaddownload div {
  display: flex;
}
.csvuploaddownload div .uloadoanload {
  border: 1px solid #ca373c;
  margin-right: 0px;
  color: #333;
  font-weight: 500 !important;
  border-radius: 5px;
  font-size: 13px !important;
  transition: 0.3s;
  padding: 2px 0px 0px;
  min-width: 50px;
  height: 40px;
  background-color: transparent;
  text-align: center;
  background-color: #fff;
}
.csvuploaddownload div .uloadoanload:hover {
  background-color: #ca373c;
  border: 1px solid rgba(255, 255, 255, 0);
}
.csvuploaddownload div .uloadoanload:hover svg {
  fill: white;
}
.csvuploaddownload div .uloadoanload:hover span {
  color: #ca373c;
  background-color: #fff;
}
.csvuploaddownload div .uloadoanload svg {
  width: 0.9em;
  height: 0.9em;
  padding: 1px 0px;
  fill: #ca373c;
}
.csvuploaddownload div .uloadoanload span {
  font-size: 8px;
  display: block;
  background-color: #ca373c;
  color: #fff;
  padding: 3px 0px;
  line-height: 1;
  border-radius: 3px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.csvuploaddrr .avatartc {
  width: 16px;
  height: 16px;
  border-radius: 50px;
  background-color: #66666e;
  margin-right: 5px;
}
.csvuploaddrr .dropzone {
  width: 100%;
  border-radius: 5px;
  height: 190px;
  line-height: 165px;
  position: relative;
}
.csvuploaddrr .dropzone p {
  position: absolute;
  bottom: -30px;
  text-align: center;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
}
.csvuploaddrr .csvfiledatalist {
  max-height: 400px;
  overflow-y: auto;
  height: 100%;
  min-height: 10rem;
}
.csvuploaddrr .csvfiledatalist .table thead tr th {
  background-color: #f3f3f3;
}
.csvuploaddrr .csvfiledatalist .table tbody tr td {
  border: none;
}
.csvuploaddrr .csvfiledatalist p {
  color: #66666e;
  margin-bottom: 10px;
}
.csvuploaddrr table tr th {
  font-size: 14px;
  padding: 0.5rem 0.2rem;
}
.csvuploaddrr table tr td {
  font-size: 14px;
  padding: 0.5rem 0.2rem;
}
.csvuploaddrr table tr td svg {
  width: 0.7em;
  height: 0.7em;
}
.csvuploaddrr .cancelcsv {
  cursor: pointer;
}

.grouptypetable > :not(caption) > * > * {
  background-color: transparent;
}

.grouptypetable {
  background-color: transparent;
}
.grouptypetable ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
.grouptypetable thead tr th {
  background-color: #ca373c20;
  font-size: 14px;
  font-weight: 500;
}
.grouptypetable tbody tr td {
  border: none;
  font-size: 14px;
}
.grouptypetable .active {
  background-color: #e6e6e9;
}

.back {
  border: none;
  background: transparent;
  padding: 0px;
}
.back svg {
  width: 0.9em;
  height: 0.9em;
}

.textdata {
  text-align: center;
  background: #f3f3f3;
  padding: 25px 15px;
  border-radius: 5px;
}

.groupEditLogo img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  padding: 0px;
  /* border-radius: 50%; */
}

.csvuploadefs {
  font-size: 13px;
}

.avcharactr {
  width: 16px;
  height: 16px;
  line-height: 16px;
  background-color: #ccc;
  font-size: 8px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  margin-right: 5px;
  font-weight: 600;
  text-transform: uppercase;
}

.modalcont .groupEditLogo img {
  -o-object-fit: contain;
  object-fit: contain;
  /* border: 2px solid #d1d1d1; */
}

.vieweye {
  position: absolute;
  right: 8px;
  top: 35px;
  cursor: pointer;
}

.filterredclear {
  padding-top: 0px;
  display: flex;
  align-items: center;
}
.filterredclear ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  max-width: 85%;
  overflow-x: auto;
}
.filterredclear ul li {
  padding: 2px 8px 2px 10px;
  background-color: #fff;
  border: 1px solid #9999a1;
  margin-right: 5px;
  font-size: 12px;
  display: flex;
  border-radius: 3px;
  transition: 0.3s;
}
.filterredclear ul li:hover {
  background-color: #9999a1;
  color: #fff;
}
.filterredclear ul li svg {
  width: 0.8em;
  height: 0.8em;
  cursor: pointer;
  padding-left: 5px;
}
.filterredclear button {
  padding: 2px 8px 2px 10px;
  background-color: #9999a1;
  border: 1px solid #9999a1;
  margin-right: 5px;
  font-size: 12px;
  display: flex;
  border-radius: 3px;
  margin-bottom: 0px;
  color: #fff;
  cursor: pointer;
}

.subscriptionbtn {
  border: none;
  background: #ca373c;
  color: #fff;
  padding: 0px 10px;
  padding-right: 5px;
  border-radius: 5px;
  font-size: 13px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  height: 30px;
  gap: 5px;
}

.subscriptionbtn svg {
  width: 1rem;
  height: 1rem;
}
.subscriptionbtn .appside , .movable-group-list .appside{
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
}

.subscriptionbtn .appside svg , .movable-group-list .appside svg  {
  transition: 300ms all ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
}
.subscriptionbtn .appside svg:first-child , .movable-group-list .appside svg:first-child {
  top: 50%;
  left: -50%;
}
.subscriptionbtn .appside svg:last-child, .movable-group-list .appside svg:last-child {
  opacity: 1;
}
.subscriptionbtn:hover .appside svg,.movable-group-list .movable-group:hover  .appside svg {
  transition: 300ms all ease;
}
.subscriptionbtn:hover .appside svg:first-child,.movable-group-list .movable-group:hover  .appside svg:first-child  {
  opacity: 1;
  top: 50%;
  left: 50%;
}
.subscriptionbtn:hover .appside svg:last-child,.movable-group-list .movable-group:hover  .appside svg:last-child   {
  left: 150%;
  opacity: 0.5;
}

.loginPage {
  background: url(../img/loginbg.svg);
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.loginPage .uno {
  width:  8rem;
  max-width: 8rem;
  object-fit: contain;
}
.loginPage h1 {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
  margin-top: 15px;
}
.loginPage h5 {
  font-size: 18px;
  margin-bottom: 0px;
}
.loginPage p {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}
.loginPage a {
  color: #ca353b;
  font-size: 14px;
  font-weight: 500;
}

.login-error {
  color: #ff4842;
  font-size: 13px;
  font-weight: 500;
  display: block;
}

.loginPage .form-group {
  margin-top: 15px !important;
}
.loginPage .form-group input {
  height: 0.7em;
  padding: 0.95em;
}
.loginPage .slogo {
  width: 8rem;
  max-width: 8rem;
  object-fit: contain;
  margin: 0 auto;
  padding-bottom: 1rem;
}
.loginPage .wecomfimgg img {
  width: 50%;
  object-fit: contain;
}

.avatar-placeholder {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50px;
  text-align: center;
  background-color: #505050;
  padding: 1px;
  margin-left: 5px;
  -o-object-fit: contain;
  object-fit: contain;
  color: #fff;
}

.successmessage {
  text-align: left;
  margin-top: 25px;
}
.successmessage h2 {
  padding-left: 30px;
  color: #66666e;
  text-align: center;
}
.successmessage .successicon {
  display: block;
  width: 25px;
  height: 25px;
}
.successmessage p {
  font-size: 16px;
  color: #66666e;
  padding-left: 8px;
}

.popupheader.delete {
  background-color: #fff;
  color: #333;
  border-bottom: none;
  text-align: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
}

.popupheading.delete {
  text-align: center;
  opacity: 70%;
}

.popupheading {
  margin-bottom: 5px !important;
  font-size: 15px;
  font-weight: 400;
}

.AlertBox {
  border-radius: 5px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  padding: 30px;
}
.AlertBox svg {
  width: 30px;
  height: 30px;
  fill: green;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.close svg {
  width: 30px;
  height: 30px;
  fill: #000;
} /*# sourceMappingURL=style.css.map */

.maindashbg {
  background-attachment: fixed;
  background-image: url('../img/dashboardbg.png');
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
  left: 90px;
  max-width: calc(100% - 90px);
  min-height: calc(100vh - 58px);
  min-width: calc(100% - 90px);
  padding: 20px 15px;
  position: relative;
  top: 58px;
}

.displaytext {
  position: absolute;
  bottom: 0px;
  display: none;
}

.displaytext .extra-tiles {
  
}

.displaytext h3 {
  font-size: 18px;
  font-weight: 500;
  color: #afafaf;
}

.displaytext p {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
}

.displaytext p svg {
  height: 1em;
  width: 1em;
  margin-right: 7px;
  margin-left: 7px;
}

.bgguysvg {
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 1;
}

.bgguysvg img {
  width: 50vw;
  height: 50vh;
  object-fit: contain;
}

.closeicon svg {
  width: 25px;
  height: 25px;
}

.instrovid {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
}

.instrovid video {
  width: 100%;
  scale: 1.5;
  height: 100%;
}

.rolemanage h5 {
  font-size: 15px;
  font-weight: 500;
}

.form-check-input:focus {
  border-color: rgb(201, 54, 58);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(201, 54, 58, 0.25);
}

.form-check-input:checked {
  background-color: #ca373c;
  border-color: #ca373c;
}

.permitem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 5px;
}

.permitem:hover {
  background-color: #ca353b;
}

.permitem:hover h3,
.permitem:hover p {
  color: #fff;
}

.permitem h3 {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
}

.form-check.permissionhere {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.form-check.permissionhere p {
  margin: 0;
  font-size: 13px;
  font-style: italic;
}

.form-check.permissionhere .form-check-input {
  margin: 0;
}

.permitem.withchild {
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 20px;
  background-color: #fff;
}

.permitem.withchild .permitem {
  padding: 10px 20px;
  width: 100%;
}

.permitem.withchild:hover h3,
.permitem.withchild:hover p {
  color: #000;
}

/* .permitem.withchild:hover .permitem h3, .permitem.withchild:hover .permitem p {
  color: #fff;
} */

.permitem.withchild .permitem h3 {
  margin: 0;
  padding: 0;
}

.permitem.withchild .permitem h3,
.permitem.withchild .permitem p {
  color: #000;
}

.permitem.withchild .permitem:hover h3,
.permitem.withchild .permitem:hover p {
  color: #fff;
}

.permitem .form-check {
  cursor: pointer;
}

.permitem .form-check-input {
  cursor: pointer;
}

.permList {
  max-height: 325px;
  overflow-y: auto;
  padding-right: 20px;
}

.permtable .substable .subheadlist {
  background-color: #ca373c50;
}

.permtable .substable {
  margin-top: 0;
}

.perm_descrip {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #000;
  display: -webkit-box;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.role_expanded_list.rolesList {
  max-height: 190px;
  overflow-y: auto;
}

.rolelistoptions i {
  font-size: 1.3rem !important;
}

.rolelistoptions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
}

.colTableStandard.subheadlist {
  background-color: #ca373c50;
  font-size: 15px;
  font-weight: 500;
}

.UserDataHere {
  margin: 15px 0;
  border-right: 1px solid #e8e8ff;
}

.UserDataHere h3 {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.UserDataHere p {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #000;
  display: -webkit-box;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.managerolemodal .UserDataHere p {
  overflow: unset;
  -webkit-box-orient: unset;
}

.rolePerRow {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 10px;
  min-height: 100px;
}
.permissionRole {
  height: auto;
}
.userselect {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 35px;
  padding-right: 2.5rem;
  max-width: fit-content;
  background-color: #e8e8ff;
  height: 25px;
  font-size: 12px;
  border-radius: 15px;
  text-transform: capitalize;
}

.userselect img {
  position: absolute;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
}

.userselect .select-all > span{
  position: relative;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  outline: 1px solid #2b2b2b90;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2b2b2b20;
}

.userselect .select-all > span svg{
  fill: #2b2b2b90;
}

.userselect .select-all.active > span{
  position: relative;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  outline: 1px solid #ca373c90;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ca373c20;
}

.userselect .select-all.active > span svg{
  fill: #ca373c90;
}

.userselect .sm-initials {
  position: absolute;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  background:#33333335;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 11px;
}

.userselect.withinput {
  position: relative;
  padding: 0 !important;
}

.userselect.withinput .userinputsearch {
  width: auto;
  min-height: 150px;
  max-width: 250px;
  max-height: 250px;
  position: absolute;
  top: 38px;
  left: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 10px;
  padding: 10px;
  overflow-y: scroll;
  z-index: 5;
}

.morehead {
  position: relative;
}

.morehead .userinputsearch {
  width: auto;
  min-height: 150px;
  max-width: 250px;
  max-height: 250px;
  position: absolute;
  top: 38px;
  left: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 10px;
  padding: 10px;
  overflow-y: hidden;
  z-index: 5;
}

.morehead .userinputsearch .listbody {
  overflow-y: scroll;
}

.userserachitem {
  padding-left: 15px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
  padding: 5px 15px 5px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  font-weight: 500;
  z-index: 5;
}

.userserachitem:hover {
  background-color: #ca373c60;
}

.userserachitem img {
  position: relative;
  width: 27px;
  height: 27px;
}

.userserachitem p {
  margin: 0;
}

.userselect.withinput input {
  width: 250px;
  border: none !important;
  border: 1px solid #dce0e4 !important;
  border-radius: 10px !important;
  z-index: 1;
}

.userselect.withinput input:focus-visible {
  box-shadow: none !important;
}

.userselect.withinput input:focus-within {
  box-shadow: none !important;
}

.rolelist {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  z-index: 1;
  margin-bottom: 10px;
}

.rolelist.atroles {
  row-gap: 0px;
  gap: 5px;
}

.morebtn {
  font-size: 13px;
  margin: 0;
  cursor: pointer;
  color: #ca353b;
}

.roleactionbtns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;
}

.roleactionbtns_child {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.roleactionbtns span {
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  margin: 0;
  padding: 3px 5px !important;
}

.roleactionbtns span svg {
  height: 17px;
  width: 17px;
}

.roleactionbtns span:hover {
  background-color: #ca373c50;
}

.UserBox {
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #e8e8ff;
  margin: 15px 0;
  z-index: 1;
}

.removeuser {
  position: absolute;
  cursor: pointer;
  right: 5px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.managerolemodal {
  min-height: 65vh;
}

.permitem {
  cursor: pointer;
}

.teamsubscription .searchfields {
  /* position: absolute;
    right: 10px;
    top: -45px; */
  max-width: 250px;
}
.settings label .css-m9hi0w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: #c2102e;
}
.settings p,
.settings label span {
  font-size: 14px;
}

.colorpicker {
  max-width: 100%;
  margin-top: 15px;
  margin-left: 2px;
  margin-right: 5px;
  background-color: #ffffff;
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
  justify-content: center;
}

.twitter-picker {
  flex: 1;
  box-shadow: none !important;
  padding: 0px !important;
}






/* HTML: <div className="loader"></div> */
.loadingdiv{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #eeeeee;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background:
    linear-gradient(0deg ,rgb(0 0 0/50%) 30%,#0000 0 70%,rgb(0 0 0/100%) 0) 50%/8% 100%,
    linear-gradient(90deg,rgb(0 0 0/25%) 30%,#0000 0 70%,rgb(0 0 0/75% ) 0) 50%/100% 8%;
  background-repeat: no-repeat;
  animation: l23 1s infinite steps(12);
}
.loader::before,
.loader::after {
   content: "";
   grid-area: 1/1;
   border-radius: 50%;
   background: inherit;
   opacity: 0.915;
   transform: rotate(30deg);
}
.loader::after {
   opacity: 0.83;
   transform: rotate(60deg);
}
@keyframes l23 {
  100% {transform: rotate(1turn)}
}


@import url(./feedbacksurvey/feedback.css);


.prd-tour{
  background: none;
    background-color: transparent;
    border: none;
    color: #ca373c;
    font-weight: 600;
    font-size: 0.85rem;
    line-height: 0.9rem;
}

.sidebar ul{
  padding-left: 0;
}

.userselect-parent{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.selectall-option .checkbox-apple {
  position: relative;
  width: 40px;
  height: 20px;
  margin: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectall-option .checkbox-apple label {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 20px;
  border-radius: 50px;
  background: linear-gradient(to bottom, #b3b3b3, #e6e6e6);
  cursor: pointer;
  transition: all 0.3s ease;
}

.selectall-option .checkbox-apple label:after {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.selectall-option .checkbox-apple input[type="checkbox"]:checked + label {
  background: linear-gradient(to bottom, #4cd964, #5de24e);
}

.selectall-option .checkbox-apple input[type="checkbox"]:checked + label:after {
  transform: translateX(21px);
}

.selectall-option .checkbox-apple label:hover {
  background: linear-gradient(to bottom, #b3b3b3, #e6e6e6);
}

.selectall-option .checkbox-apple label:hover:after {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.selectall-option .yep {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 20px;
}

.selectall-option {
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 0.5rem;
  flex-direction: column;
}

.selectall-option p{
  margin: 0;
  font-weight: 500;
  color: #333;
  font-size: 0.9rem;
}

.DefaultRedBtn{
  border: 1px solid #CA373C;
  border-radius: 5px;
  background: #CA373C;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 7px 15px;
  font-weight: 400;
  font-size: 0.9rem;
  height: 35px;
}

button.DefaultRedBtn.pro {
  gap: 9px;
  padding: 0 15px;
  background-color: transparent;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  letter-spacing: 0.8px;
  border-radius: 10px;
  transition: all .3s;
  border: 1px solid rgba(156, 156, 156, 0.466);

}

button.DefaultRedBtn.pro p{
      font-size: 0.85rem;
      margin: 0;
      width: max-content;
      color: #333;
      font-weight: 500;
}

button.DefaultRedBtn.pro svg{
  width: 1.3rem;
  height: 1.3rem;
}

button.DefaultRedBtn.pro svg path{
  fill: #fdbc68;
}

.googlebtn .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0;
  padding-top: 17px;
}

.googlebtn .ball {
  --size: 10px;
  width: var(--size);
  height: var(--size);
  border-radius: 11px;
  margin: 0 3px;
  margin-top: -25px;
  animation: 1.5s bounce ease infinite;
}

.googlebtn .blue {
  background-color: #4285f5;
}

.googlebtn .red {
  background-color: #ea4436;
  animation-delay: 0.25s;
}

.googlebtn .yellow {
  background-color: #fbbd06;
  animation-delay: 0.5s;
}

.googlebtn .green {
  background-color: #34a952;
  animation-delay: 0.75s;
}

.disableBtn {
  filter: grayscale(1);
  opacity: 0.8;
  cursor: no-drop !important;
}

@keyframes bounce {
  50% {
    transform: translateY(10px);
  }
}

span.inactive-user {
  font-size: 0.7rem;
  font-weight: 500;
  position: absolute;
  top: 2%;
  right: 2%;
  color: #ca373c;
  background-color: #ca373c20;
  padding: 2px 6px;
  border-radius: 5px;
}

/* .form-group.selectall{
  display: block;
} */

.form-group.selectall p.active{
  font-size: 0.9rem;
  color: #43a837;
  font-weight: 500;
  margin: 0;
}

.displaytext.extra-tiles {
  position: relative;
  margin-top: 3rem;
}

.displaytext.extra-tiles p{
  margin-bottom: 0;
}

.download-text{
  color: #ca373c;
  font-weight: 500;
}

.modal-footer-btn{
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: row;
  gap: 0.5rem;
  margin: 0.5rem 0;
}

.profile-Logo{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}

.profile-Logo p{
  font-weight: 500;
  font-size: 0.8rem;
  color: #000;
  margin: 0;
}

.groupEditLogo {
  overflow: hidden;
  border: 2px solid #d1d1d1;
  width: 5.5rem;
  height: 5.5rem;
  max-width: 5.5rem;
  max-height: 5.5rem;
  border-radius: 50%;
  background-color: #d1d1d150;
}

.closeicon {
  background: #ca373c9e;
  position: absolute;
  left: 50%;
  bottom: -25px;
  transform: translate(-50%, -50%);
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0 11px 0;
  cursor: pointer;
}

.closeicon svg {
  fill: #fff;
  width: 1.2rem;
  height: 1.2rem;
  object-fit: contain;
}

.modalheader {
  background-color: #ca373c;
  color: #fff;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.modalheader span {
  cursor: pointer;
}
.modalheader span svg {
  height: 1.7rem;
  width: 1.7rem;
}

/* input {
  margin-top: 0.5rem;
  border: 1.5px solid #d3d3d3;
  border-radius: 5px;
  box-shadow: 0 0 20px -18px;
  height: 35px;
  margin-top: 10px;
  outline: none;
  padding: 5px 15px;
  transition: all .3s cubic-bezier(.19,1,.22,1);
}

input:focus, input:focus-visible, input:focus-within {
  border: 1.5px solid #70707090;
  border-radius: 5px;
  background: #F8F8FA 0% 0% no-repeat padding-box;
  box-shadow: 0 0 20px -18px;
  height: 35px;
  margin-top: 10px; 
  outline: none;
  padding: 5px 15px;
  transition: all .3s cubic-bezier(.19,1,.22,1);
} */

p.Mui-error,p.errormessage{
    color: #FF4842 !important;
    margin: 0 !important;
    font-size: 0.7rem !important;
    padding: 0 !important;
}

p.successmessage{
  color: green !important;
  margin: 0 !important;
  font-size: 0.7rem !important;
  padding: 0 !important;
}



button.save.loading{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor:not-allowed;
  width: 6rem;
  }
  
button.save.loading svg {   
  width: 2em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

button.save.loading circle {
  fill: none;
  stroke: #fff;
  stroke-width: 4;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

@keyframes rotate4 {
  100% {
   transform: rotate(360deg);
  }
 }
 

    
 @keyframes dash4 {
  0% {
   stroke-dasharray: 1, 200;
   stroke-dashoffset: 0;
  }
 
  50% {
   stroke-dasharray: 90, 200;
   stroke-dashoffset: -35px;
  }
 
  100% {
   stroke-dashoffset: -125px;
  }
 }
 

 .form-group.only-check {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
  margin-top: 0.6rem;
}

.form-group.only-check p{
  margin-bottom: 0;
}

.form-group.only-check label > span{
  padding: 0;
}

.form-group.only-check label{
  margin: 0;
}

.form-group.only-check label > span.Mui-checked{
  color: #ca373c;
}

.groupdetail .bdetail .information-group{
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  gap: 0.35rem;
  border-top: none;
}

.groupdetail .bdetail .information-group.for-user{
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  gap: 0.25rem;
  border-top: none;
}

.groupdetail .bdetail .information-group.for-user .desig{
  font-weight: 500;
  padding: 0px 10px;
  font-weight: 500;
}

.groupdetail .bdetail .information-group .desig{
  font-weight: 500;
  padding: 0px 10px;
  font-weight: 500;
}

.groupdetail .bdetail .information-group span{
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  width: fit-content;
  height: 100%;
  margin: 0;
}

.groupdetail .bdetail .information-group.for-user span{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: fit-content;
  height: 100%;
  margin: 0;
  gap: 0.5rem;
}


.groupdetail .bdetail .information-group.for-user span .info-icon.active{
  min-width: 1rem;
  min-height: 1rem;
  max-width: 1rem;
  max-height: 1rem;
  opacity: 1;
  border-radius: 50%;
  background-color: #4cd32a;
  border: 2px solid #f3f3f3;
}

.information-group.for-user .inactive-status{
  background: #ffdddd;
  padding: 2px 10px;
  border-radius: 3px;
}

.information-group.for-user .active-status{
  background: #ddffdd;
  padding: 2px 10px;
  border-radius: 3px;
}

.groupdetail .bdetail .information-group.for-user span .info-icon svg{
  width: 1.3rem;
  height: 1.3rem;
  opacity: 0.8;
  margin-top: -2px;
}

.groupdetail .bdetail .information-group span:hover{
  background-color: transparent;
}

.groupdetail .bdetail .information-group span h5{
  font-size: 0.8rem;
  color: #767676;
  margin: 0;
  font-weight: 500;
  text-transform: uppercase;
}

.groupdetail .bdetail .information-group span p{
  font-size: 0.85rem;
  color: #333333;
  font-weight: 400;
}

.active-status{
  color: green !important;
  font-weight: 500 !important;
}

.inactive-status{
  color: red !important;
}

span.cursor-pointer.reset.passkey{
  font-size: 0.8rem;
  color: #ca373c;
  /* text-decoration: underline; */
  width: max-content;
  padding: 0 5px;
  font-weight: 500;
}


span.cursor-pointer.reset.passkey:hover{
  background-color: transparent;
  text-decoration: underline;
}

.businesslogo .businesslogoimg .userActive {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: #4CAF50;
  border: 2px solid #fff;
}

.businesslogo .businesslogoimg .userInactive {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: #818181;
  border: 2px solid #fff;
}

.businesslogo{
  position: relative;
}

.groupdetail .bdetail span.detail-action-btn{
  width: max-content;
  height: auto;
  font-weight: 500;
  font-size: 0.85rem;
  padding: 0 0.25rem;
  color: #ca373c;
}


.moving-box .modalheader{
  background-color: #fff;
  color: #000;
  font-weight: 500;
  border-bottom: none;
  padding-bottom: 0;
  font-size: 1.1rem;
}

.separate {
  color: #2d2d2d90;
}

.move-chain, .move-chain-item{
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
  color: #000;
}

.nameemail {
  align-items: center;
  background-color: #4d4d4d05;
  border: .5px solid #4d4d4d10;
  border-radius: 19px;
  display: flex;
  gap: .5rem;
  justify-content: start;
  padding-left: 0;
  padding-right: 10px;
}

.movable-group .nameemail{
  border: none;
}

.nameemail .avatar-placeholder{
  border-radius: 50px;
  color: #fff;
  line-height: 30px;
  margin-left: 0px;
  object-fit: contain;
  text-align: center;
  background-color: #505050;
  height: 30px;
  padding: 1px;
  width: 30px;
  overflow: hidden;
}

.nameemail .avatar-placeholder img{
  width: 100%;
  height: 100%;
}

.nameemail p {
  font-size: .9rem;
  margin: 0;
  color: #000;
}

.move-chain-item .nameemail p {
  font-weight: 500;
  font-size: 0.95rem;
}

.move-chain-item p{
  font-weight: 400;
  font-size: 0.95rem;
  margin: 0;
}


.group-breadcrumbs{
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
  color: #000;
}

.group-breadcrumbs .separate{
  font-size: 1.5rem;
}

.group-breadcrumbs-item{
  font-size: 0.95rem;
  font-weight: 400;
  cursor: pointer;
}

.group-breadcrumbs-item:hover{
  text-decoration: underline;
}

.group-breadcrumbs-item.active{
  font-weight: 500;
}

.group-breadcrumbs-item.active:hover{
  text-decoration: none;
}

.movable-group-list{
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  gap: 0.15rem;
  height: 40vh;
  min-height: 300px;
  max-height: 500px;
  overflow-y: auto;
  border-top: 1px solid #2d2d2d50 ;
  border-bottom: 1px solid #2d2d2d50 ;
}

.empty.movable-group-list {
  justify-content: center;
  align-items: center;
}



.movable-group-list .movable-group{
  display: flex;
  align-items: center;
  padding: 0.3rem  0.3rem;
  justify-content: space-between;
  width: 100%;
}

.movable-group-list .movable-group:hover{
  background-color: #2b2b2b30;
  cursor: pointer;
}

.movable-group-list .movable-group:hover{
  background-color: #00000000;
}

.active-label{
  color: green;
  font-weight: 500;
  font-size: 0.85rem;
}

.inactive-label{
  color: gray;
  font-weight: 500;
  font-size: 0.85rem;
}