@media (max-width: 767px) {
  .sidebar.sidebaractive {
    max-width: 0px;
  }
  .rangeselect {
    justify-content: start;
  }
  .mobiledash {
    width: 100%;
    max-width: 100%;
    left: 0px;
  }
  .header .userdropdown .dropdown-menu {
    width: 240px !important;
    left: -175px;
  }
  .maindashbg {
    background-image: inherit;
  }
  .sidebar {
    box-shadow: 0px 12px 9px 1px rgba(219, 219, 219, 0.8);
  }
  .createbtn {
    padding: 8px;
  }
  .groupdetail {
    padding: 20px 0px 20px 20px;
  }
  .groupdetail .bdetail h5 {
    font-size: 1.05rem;
  }
  .searchfields {
    margin-top: 15px;
  }
  .groupbody .groupdetails {
    width: 100%;
  }
  .groupbody .groupdetails .cn {
    height: inherit;
  }
  .groupcardback .backdetails .grouplogo img {
    -o-object-fit: contain;
       object-fit: contain;
  }
  .applist {
    min-height: 12vh;
  }
}
@media (max-width: 1250px) {
  .instrovid video {
    width: 100%;
    scale: 1.7;
    height: 100%;
  }
}
@media (max-width: 1100px) {
  .instrovid video {
    width: 100%;
    scale: 2;
    height: 100%;
  }
}
@media (max-width: 900px) {
  .instrovid video {
    width: 100%;
    scale: 2.3;
    height: 100%;
  }
}
@media (max-width: 900px) {
  .instrovid video {
    min-width: none;
    scale: 3;
    height: 100%;
  }
}
@media (max-width: 700px) {
  .instrovid video {
    min-width: none;
    scale: 3.5;
    height: 100%;
  }
}
@media (min-width: 1450px) {
  .managerolemodal {
    min-height: 55vh;
  }
  .permList {
    max-height: 700px;
  }
  .userselect {
    height: 30px;
    font-size: 14px;
  }
  .userselect img {
    width: 25px;
    height: 25px;
  }
  .tabsproduct .nav-item .nav-link {
    font-weight: 500;
    border-bottom: 1px solid #ccc;
  }
  .tabsproduct .nav-item .nav-link:hover {
    border-bottom: 1px solid #ca373c;
  }
  .tabsproduct .nav-item .nav-link.active,
  .tabsproduct .nav-item .show > .nav-link {
    font-weight: 500;
    border-bottom: 1px solid #ca373c;
  }
  .PopupModal .pro-detail-box .detail-side .text-parent .text-detail h5 {
    font-size: 2rem;
  }
  .PopupModal .pro-detail-box .detail-side .text-parent .text-detail h6 {
    font-size: 1rem;
  }
  .PopupModal .pro-detail-box .detail-side .text-parent .text-list-detail ul li {
    font-size: 0.9rem;
    padding-bottom: 0.7rem;
  }
  .PopupModal .pro-detail-box .detail-side .text-parent .pro-btn {
    font-size: 0.8rem;
  }
  .PopupModal .pro-detail-box .bottom {
    padding: 1rem 1rem 1rem 1.5rem;
  }
}
@media (max-width: 1700px) {
  .Coming-Soon {
    font-size: 10px;
    position: absolute;
    right: 5px;
    line-height: 1.1;
  }
  .Appbox .appdata .appdetails {
    font-size: 12px;
  }
  .Appbox .appdata .apptitle p {
    font-size: 13px;
  }
}
@media (max-width: 1500px) {
  .groupdetail .bdetail h5 {
    font-size: 1rem;
    padding-bottom: 0.6rem;
  }
  .groupdetail .bdetail .information-group span p {
    font-size: 0.75rem;
  }
  .groupdetail .bdetail span {
    width: 1.3rem;
    height: 1.3rem;
  }
  .groupdetail .bdetail h5 svg {
    width: 16px;
    height: 16px;
  }
  .csvuploaddownload div .uloadoanload svg {
    width: 0.7em;
    height: 0.7em;
    padding: 1px 0px;
    fill: #ca373c;
  }
  .csvuploaddownload div .uloadoanload {
    padding: 0px 0px 0px;
    height: 35px;
  }
  .list-here .application-card .app-logo span.in-beta {
    font-size: 0.75rem;
  }
  .list-here .application-details .application-name {
    font-size: 0.85rem;
  }
  .createbtn {
    height: 35px;
    font-size: 0.75rem;
  }
  .list-here .application-card .app-logo {
    width: 3rem;
    height: 3rem;
  }
  .list-here .application-details .goto-application {
    font-size: 0.8rem;
  }
  .PopupModal .pro-detail-box .detail-side .text-parent .text-detail h5 {
    font-size: 2rem;
  }
  .PopupModal .pro-detail-box .detail-side .text-parent .text-detail h6 {
    font-size: 1rem;
  }
  .PopupModal .pro-detail-box .detail-side .text-parent .text-list-detail ul li {
    font-size: 0.9rem;
    padding-bottom: 0.7rem;
  }
  .PopupModal .pro-detail-box .detail-side .text-parent .pro-btn {
    font-size: 0.8rem;
  }
  .PopupModal .pro-detail-box .bottom {
    padding: 1rem 1rem 1rem 1.5rem;
  }
  .bottom .company-text {
    font-size: 0.7rem;
    color: #333333;
    font-weight: 500;
  }
  .bottom .company-logos img {
    width: 1.8rem;
    height: 2rem;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .PopupModal .pro-detail-box .detail-side .text-parent {
    padding-bottom: 1rem;
  }
  button.DefaultRedBtn.pro {
    gap: 5px;
    padding: 0 10px;
  }
  button.DefaultRedBtn.pro svg {
    width: 1.2rem;
    height: 1.2rem;
  }
  button.DefaultRedBtn.pro p {
    font-size: 0.8rem;
  }
  .onload-section .info-box img {
    width: 5rem;
  }
  .onload-section .info-box h6 {
    font-size: 1rem;
    font-weight: 600;
    padding-top: 1.5rem;
    margin-bottom: 0;
  }
  .onload-section .info-box p {
    font-size: 0.85rem;
    font-weight: 600;
  }
  .onload-section .info-box button {
    font-size: 0.8rem;
  }
  .onload-section .info-box {
    gap: 0;
  }
  .onload-section .active-overlay {
    min-width: 800px;
  }
  .modalheader {
    font-size: 0.95rem;
    padding: 0.7rem 1rem;
  }
  .dropzone,
  .groupEditLogo {
    width: 5rem;
    height: 5rem;
    max-width: 5.5rem;
    max-height: 5.5rem;
    line-height: 5rem;
  }
  .closeicon svg {
    width: 1rem;
    height: 1rem;
  }
  .closeicon {
    bottom: -26px;
    padding: 3px 0 13px 0;
  }
  .createform p,
  .select-option-radio p {
    font-size: 0.87rem !important;
    font-weight: 500;
  }
  p.Mui-error,
  p.errormessage {
    color: #FF4842 !important;
    margin: 0 !important;
    font-size: 0.7rem !important;
    padding: 0 !important;
  }
  .createform input {
    font-size: 0.85rem !important;
    height: 16px;
    padding-left: 10px;
  }
  .createform .with-select input {
    height: 20px;
  }
  .form-group.with-select div,
  .select-option-radio .radio-options label span,
  .form-group.with-select div span,
  .form-group.with-select span {
    font-size: 0.85rem !important;
  }
  .modal-footer-btn button,
  .modal-footer-btn button.save.loading {
    height: 36px;
    font-size: 0.75rem;
    min-width: 6rem;
  }
}
@media (max-width: 800px) {
  .onload-section .active-overlay {
    min-width: 100vw;
  }
}
/* Responsive Cards CSS Starts */
@media (min-width: 300px) {
  .list-here {
    --cols: 1 ;
  }
  .list-here .application-card {
    max-width: 100%;
  }
}
@media (min-width: 350px) {
  .list-here {
    --cols: 1 ;
  }
  .list-here .application-card {
    max-width: 100%;
  }
}
@media (min-width: 450px) {
  .list-here {
    --cols: 1 ;
  }
  .list-here .application-card {
    max-width: 100%;
  }
}
@media (min-width: 600px) {
  .list-here {
    --cols: 2 ;
  }
  .list-here .application-card {
    max-width: 100%;
  }
}
@media (min-width: 850px) {
  .list-here {
    --cols: 3 ;
  }
  .list-here .application-card {
    max-width: 100%;
  }
}
@media (min-width: 1000px) {
  .list-here {
    --cols: 4 ;
  }
  .list-here .application-card {
    max-width: 250px;
  }
}
@media (min-width: 1250px) {
  .list-here {
    --cols: 4 ;
  }
  .list-here .application-card {
    max-width: 100%;
  }
}
@media (min-width: 1400px) {
  .list-here {
    --cols: 5 ;
  }
  .list-here .application-card {
    max-width: 315px;
  }
}
@media (min-width: 1750px) {
  .list-here {
    --cols: 5 ;
  }
  .list-here .application-card {
    max-width: 340px;
  }
  .list-here .application-card .app-logo {
    width: 4rem;
    height: 4rem;
  }
  .list-here .application-details .application-name {
    font-size: 1.05rem;
  }
  .list-here .application-details .application-des {
    font-size: 0.8rem;
  }
}
@media (min-width: 2000px) {
  .list-here {
    --cols: 6 ;
  }
  .list-here .application-card {
    max-width: 100%;
  }
}
@media (min-width: 2300px) {
  .list-here {
    --cols: 7 ;
  }
  .list-here .application-card {
    max-width: 100%;
  }
}
/* Responsive Cards CSS Ends */
@media (min-width: 1500px) {
  .displaytext {
    display: block;
  }
}
@media (max-width: 400px) {
  .login-section {
    flex-direction: column;
  }
  .login-section .left {
    display: none;
  }
  .login-section .right {
    width: 100%;
  }
}
@media (max-width: 551px) {
  .login-section {
    flex-direction: column;
  }
  .login-section .left {
    display: none;
  }
  .login-section .right {
    width: 100%;
  }
}
@media (max-width: 769px) {
  .login-section {
    flex-direction: column;
  }
  .login-section .left {
    display: none;
  }
  .login-section .right {
    width: 100%;
  }
}/*# sourceMappingURL=responsive.css.map */